import React,{useState} from 'react';
import FetchTableDataComponent from "./FetchTableDataComponent";
import './pagination.css'
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
export default function Pagination( props : any) {
    const { currentPage, maxPageLimit, minPageLimit, totalPages } = props;
    const pages: any = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }
    const handlePrevClick = () => {
        props.onPrevClick();
    }

    const handleNextClick = () => {
        props.onNextClick();
    }

    const handlePageClick = (e : any) => {
        props.onPageChange(Number(e.target.id));
    }
    const pageNumbers = pages.map((page:any) => {

        if (page <= maxPageLimit && page > minPageLimit) {
            return (
                <li key={page} id={page} onClick={handlePageClick}
                    className={currentPage === page ? 'active' : undefined}>
                    {page}
                </li>
            );
        } else {
            return null;
        }
    }

    );

    // page ellipses
    let pageIncrementEllipses:any = null;
    if (pages.length > maxPageLimit) {
        pageIncrementEllipses = <li onClick={handleNextClick}>&hellip;</li>
    }
    let pageDecremenEllipses:any = null;
    if (minPageLimit >= 1) {
        pageDecremenEllipses = <li onClick={handlePrevClick}>&hellip;</li>
    }
    const [state, setState] = React.useState([
        "username", "firstname", "duration"
    ]);
    return (
        <div className="main">
            <ul className="pageNumbers"> 
               <li>
                   <Button variant="danger" onClick={handlePrevClick} disabled={currentPage === pages[0]}>Prev</Button>
               </li>
               {pageDecremenEllipses}
                {pageNumbers}
               {pageIncrementEllipses}
                <li>
                   <Button variant="danger" onClick={handleNextClick} disabled={currentPage === pages[pages.length-1]}>Next</Button>
               </li>
            </ul>
        </div>
    )
}