import React, {useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useEffect} from "react";
import {getTotalListeningTime} from "../crowd_source.service";
import {getLoggedInUserId} from "../../../common/http";
import TimeCalculationComponent from "./TimeCalculationComponent";

export default function ListenerComponent(){

    const speakerId = getLoggedInUserId();
    const [audioTime,setAudioTime] = useState();
    const [hour,setHour] = useState(0);
    const [min,setMin] = useState(0);
    const [sec,setSec] = useState(0);


    useEffect(()=>{

        (async () => {
            // await getSpeakerScript();
            // await getTotalAudioFile();
            await getTotalAudioTime();
        })();

    },[])


    const getTotalAudioTime = async () =>{

        let res = await getTotalListeningTime(speakerId);
        if(res.status == 200 ){

            let {data} = await res.json();
            setAudioTime(data);

            setHour(Math.floor(data/3600));
            const rem_time = data % 3600;

            setMin(Math.floor(rem_time / 60));


            setSec(Math.ceil(rem_time % 60));

        }
    }

    return(
        <div>
            <h1><strong>Listener Dashboard</strong></h1>

            <div>
                <Row>

                    <Col md={4}>
                        <Card style={{
                            background:"#E6E6FA"
                        }}>
                            <Card.Body>
                                <strong>যাচাইকৃত অডিওর মোট সময়(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                <br />
                                <strong>

                                    {hour} : {min} : {sec}

                                </strong>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card style={{
                            background:"#EFA315"
                        }}>
                            <Card.Body>
                                <div>
                                    <strong>রিভ যাচাই নিরীক্ষার সময় (ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                    <br />
                                    <strong>
                                        0 : 0 : 0
                                    </strong>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>



            </div>

            <div style={{
                marginTop:"20px"
            }}>
                <Row>

                    <Col md={4}>
                        <Card style={{
                            background:"#61A917"
                        }}>
                            <Card.Body>
                                <strong>মোট গ্রহণযোগ্য সময়  (ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                <br />
                                <strong>

                                    0 : 0 :0

                                </strong>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card style={{
                            background:"#E44609"
                        }}>
                            <Card.Body>
                                <strong>মোট অগ্রহণযোগ্য সময় (ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                <br />
                                <strong>
                                    0 : 0 : 0
                                </strong>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </div>
        </div>
    )
}