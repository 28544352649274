import React, { useState } from "react";
import "./all.scss";
import "./ttsstyle2.scss";
import "./style.scss";
import "./home.scss";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl_logo from "./images/bccl_ogo.png";
import ict_logo from "./images/ict_logo.png";
import signup from "./images/stts/signup.png";
import upload from "./images/stts/upload.png";
import Form from "react-bootstrap/Form";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import {
  ToastFailedMsg,
  ToastSuccessMsg,
  ToastWarningMsg,
} from "../../common/toast";
import { registrationUserPython } from "./crowd_source.service";
import { AiFillCaretRight, AiFillFacebook } from "react-icons/ai";
import { setToken } from "../../common/http";
import { useHistory } from "react-router-dom";
import MenuComponent from "./Menu.Component";
import Header from "./Header";
import { IconContext } from "react-icons";
export default function SignUpPage() {
  const [isChecked, setIsChecked]: any = useState(false);
  const [nameError, setNameError]: any = useState(false);
  const [ageError, setAgeError]: any = useState(false);
  const [areaError, setAreaError]: any = useState(false);
  const [genderError, setGenderError]: any = useState(false);
  const [emailError, setEmailError]: any = useState(false);
  const [passwordError, setPasswordError]: any = useState(false);
  const [phoneError, setPhoneError]: any = useState(false);
  const [mobileBankError, setMobileBankError]: any = useState(false);
  const [checked, setChecked]: any = useState(false);
  const [organizationError, setOrganizationError]: any = useState(false);
  const [fillAllFields, setFillAllFields]: any = useState(false);
  // Check box show hide
  let select = "select";
  const history = useHistory();
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  // const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
  const phoneRegex = /^01[0-9]{9}$/i;

  let {
    economic_situation,
    gender,
    education_level,
    locality,
    profession,
    places,
    agegroup,
  }: any = {
    economic_situation: ["--নির্বাচন করুন--", "নিম্নবিত্ত", "মধ্যবিত্ত", "উচ্চবিত্ত"],
    gender: ["--নির্বাচন করুন--", "পুরুষ", "নারী"],
    education_level: [
      "--নির্বাচন করুন--",
      "স্বল্প শিক্ষিত ",
      "মাধ্যমিক",
      "উচ্চ মাধ্যমিক",
      "স্নাতক",
      "স্নাতকোত্তর",
    ],
    places: [
      "--নির্বাচন করুন--",
      "যশোর",
      "সাতক্ষীরা",
      "মেহেরপুর",
      "নড়াইল",
      "চুয়াডাঙ্গা",
      "কুষ্টিয়া",
      "মাগুরা",
      "খুলনা",
      "বাগেরহাট",
      "ঝিনাইদহ",
      "কুমিল্লা",
      "ফেনী",
      "ব্রাহ্মণবাড়িয়া",
      "রাঙ্গামাটি",
      "নোয়াখালী",
      "চাঁদপুর",
      "লক্ষ্মীপুর",
      "চট্টগ্রাম",
      "কক্সবাজার",
      "খাগড়াছড়ি",
      "বান্দরবান",
      "নরসিংদী",
      "গাজীপুর",
      "শরীয়তপুর",
      "নারায়ণগঞ্জ",
      "টাঙ্গাইল",
      "কিশোরগঞ্জ",
      "মানিকগঞ্জ",
      "ঢাকা",
      "মুন্সিগঞ্জ",
      "রাজবাড়ী",
      "মাদারীপুর",
      "গোপালগঞ্জ",
      "ফরিদপুর",
      "ঝালকাঠি",
      "পটুয়াখালী",
      "পিরোজপুর",
      "বরিশাল",
      "ভোলা",
      "বরগুনা",
      "শেরপুর",
      "ময়মনসিংহ",
      "জামালপুর",
      "নেত্রকোণা",
      "পঞ্চগড়",
      "দিনাজপুর",
      "লালমনিরহাট",
      "নীলফামারী",
      "গাইবান্ধা",
      "ঠাকুরগাঁও",
      "রংপুর",
      "কুড়িগ্রাম",
      "সিরাজগঞ্জ",
      "পাবনা",
      "বগুড়া",
      "রাজশাহী",
      "নাটোর",
      "জয়পুরহাট",
      "চাঁপাইনবাবগঞ্জ",
      "নওগাঁ",
      "সিলেট",
      "মৌলভীবাজার",
      "হবিগঞ্জ",
      "সুনামগঞ্জ"
    ].sort(),
    locality: [
      "BARENDRI",
      "RAJBONGSHI",
      "KAMRUPI",
      "MIDDLE_EAST",
      "NORTH_EAST",
      "SOUTH_EAST",
      "CHATTOGRAM",
      "NOAKHALI",
      "ADIVASHI",
    ].sort(),
    profession: ["--নির্বাচন করুন--", "স্বনির্ভর", " চাকরিজীবী", " শিক্ষার্থী", "কর্মহীন"],
    agegroup: ["--নির্বাচন করুন--", "০৭-১৪", "১৫-২৪", "২৫-৩৪", "৩৫-৪৪", "৪৫-৫৯", "৬০-৭৫"],
  };
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    nid: "",
    username: "",
    password: "",
    gender: "",
    role: "",
    age: "",
    elementarySchoolLocation: "",
    profession: "",
    economic_situation: "",
    education_level: "",
    dateOfBirth: "",
    organization: "",
    mobileBank: "",
  });
  const [validated, setValidated] = useState(false);
  let [username, setUsername]: any = useState("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let result = e.target.value;

    // console.log("event name " + e.target.name, " ", result);
    if (e.target.name === "fullname") {
      if (result.length < 3) {
        // console.log("Name length ", result.length);
        setNameError(true);
      } else {
        setNameError(false);
      }
    }

    if (e.target.name === "email") {
     if (emailRegex.test(result) || phoneRegex.test(result)) {
        setUsername(result.trim());
        setEmailError(false);
      } else {
        setEmailError(true);
      }

      // if(e.target.value.includes("@")){
      //     setUsername(result.trim())
      // }else{
      //     setUsername(result.replace("+88", ""));
      // }
    }

    if (e.target.name === "password") {
      if (result.length < 6 || result.includes(" ")) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }

    if (e.target.name === "phone") {
      if (phoneRegex.test(result)) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: result,
    });
  };

  const onUserProfileInputChange = (e: any) => {
    let temp = { ...formData };

    let res = e.target.value;

    if (e.target.name === "age") {
      if (e.target.value === "" || e.target.value === "--নির্বাচন করুন--") {
        console.log("age ", e.target.value);

        setAgeError(true);
      } else if (e.target.value == "০৭-১৪") {
        setAgeError(false);
        // @ts-ignore
        temp[e.target.name] = "০৭-১৪";
        setFormData(temp);
      } else if (e.target.value === "১৫-২৪") {
        setAgeError(false);
        // @ts-ignore
        temp[e.target.name] = "১৫-২৪";
        setFormData(temp);
      } else if (e.target.value === "২৫-৩৪") {
        setAgeError(false);
        // @ts-ignore
        temp[e.target.name] = "২৫-৩৪";
        setFormData(temp);
      } else if (e.target.value === "৩৫-৪৪") {
        setAgeError(false);
        // @ts-ignore
        temp[e.target.name] = "৩৫-৪৪";
        setFormData(temp);
      } else if (e.target.value == "৪৫-৫৯") {
        setAgeError(false);
        // @ts-ignore
        temp[e.target.name] = "৪৫-৫৯";
        setFormData(temp);
      } else {
        setAgeError(false);
        // @ts-ignore
        temp[e.target.name] = "৬০-৭৫";
        setFormData(temp);
      }
    }
    else if (e.target.name === "elementarySchoolLocation") {
      if (e.target.value === "" || e.target.value === "--নির্বাচন করুন--") {
        console.log("elementaryschool ", e.target.value);

        setAreaError(true);
      } else {
        setAreaError(false);
        // @ts-ignore
        temp[e.target.name] = e.target.value;
        setFormData(temp);
      }
    }
    else if (e.target.name === "gender") {
      if (e.target.value === "" || e.target.value === "--নির্বাচন করুন--") {
        console.log("gender ", e.target.value);

        setGenderError(true);
      } else {
        setGenderError(false);
        // @ts-ignore
        temp[e.target.name] = e.target.value;
        setFormData(temp);
      }
    }
    else {
      // @ts-ignore
      temp[e.target.name] = e.target.value;
      setFormData(temp);
    }
  };

  const navigatePage = () => {
    history.push("/log-in");
    window.location.reload();
  };

  let detailsObj: any = new Object();
  const [disabled, setDisabled]: any = useState(false);
  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    detailsObj.permanent = "";
    detailsObj.present = "";
    if (formData.elementarySchoolLocation != "") {
      detailsObj.esl = formData.elementarySchoolLocation;
    }


    if (formData.organization == "" || !organizationError) {
      console.log(organizationError)
      if (checkBoxSelect1) {
        detailsObj.org = "Crebsol";
        //detailsObj["organization"]="Crebsol"
        //detailsObj.push({[property]:"Crebsol"});
        // formData.details=JSON.stringify(detailsObj);
        formData.organization = "Crebsol";
      }
      else if (checkBoxSelect2) {
        detailsObj.org = "BUBT";
        //detailsObj.push({[property]:"DU"});
        // formData.details=JSON.stringify(detailsObj);
        formData.organization = "BUBT";
      }
      else if (checkBoxSelect3) {
        detailsObj.org = "Tribal";
        //detailsObj.push({[property]:"BUBT"});
        // formData.details=JSON.stringify(detailsObj);
        formData.organization = "Tribal"
      }
      else if (checkBoxSelect4) {
        detailsObj.org = "Leading";
        //detailsObj.push({[property]:"JnU"});
        // formData.details=JSON.stringify(detailsObj);
        formData.organization = "Leading"
      }
      else if (checkBoxSelect5) {
        detailsObj.org = "Others";
        //detailsObj.push({[property]:"Others"});
        // formData.details=JSON.stringify(detailsObj);
        formData.organization = "Others"
      }
      else {
        setOrganizationError(true);
      }
    }

    if (formData["fullname"].length < 3) {
      setNameError(true);
      return;
    }

    if (formData.email === "") {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      setEmailError(true);
      return;
    }
    if (formData.password === "") {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      setPasswordError(true);
      return;
    }
    if (formData.phone === "") {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      setPhoneError(true);
      return;
    }
    if (formData.mobileBank === "") {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      setMobileBankError(true);
      return;
    }

    if (emailError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      return;
    }

    if (passwordError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      return;
    }

    if (phoneError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      return;
    }

    if (mobileBankError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      return;
    }

    if (formData.elementarySchoolLocation === "") {
      setAreaError(true);
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      console.log("area error")
      return;
    }

    if (areaError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      console.log("area error")
      return;
    }

    if (formData.age === "") {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      setAgeError(true);
      console.log("age error")
      return;
    }

    if (ageError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      console.log("age error")
      return;
    }

    if (formData.gender === "") {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন")
      setGenderError(true);
      console.log("gender error")
      return;
    }

    if (genderError) {
      ToastWarningMsg("খালি ঘরগুলো পূরণ করুন");
      console.log("gender error");
      return;
    }

    if (!isChecked) {
      ToastWarningMsg("সম্মতি দিন");
      return;
    }

    if (emailRegex.test(username.toLowerCase()) || phoneRegex.test(username.toLowerCase())) {
      formData["username"] = username;
    } else {
      setEmailError(true);
      return;
    }
    console.log(formData)
    if (formData.education_level === "--নির্বাচন করুন--") {
      formData.education_level = "";
    }
    if (formData.economic_situation === "--নির্বাচন করুন--") {
      formData.economic_situation = "";
    }
    if (formData.profession === "--নির্বাচন করুন--") {
      formData.profession = "";
    }
    setDisabled(true);
    //setTimeout(() => setDisabled(false), 2500);
    //console.log
    setShowModal(true);
    // let res = await registrationUserPython(formData);
    // if ((res.status === 200 || res.status === 201)) {
    //   setDisabled(false);
    //   let { userCreated } = await res.json();
    //   console.log("Registered Data ", userCreated);
    //   if (userCreated == "Yes") {
    //     ToastSuccessMsg("নিবন্ধন সম্পন্ন হয়েছে। তথ্য জমা দিন");
    //     setTimeout(() => navigatePage(), 2000);
    //   } else {
    //     ToastFailedMsg("ইউজারনেমটি আছে");
    //     // setTimeout(() => handleClose(), 2000);
    //   }
    // } else {
    //   ToastFailedMsg("নেটওয়ার্ক সমস্যা। আবার চেষ্টা করুন");
    //   // setTimeout(() => handleClose(), 2000);
    // }
  };

  const [open, setOpen]: any = useState(false);

  const onToggle = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleOnChange = () => {
    console.log("Checked ", !isChecked);
    setIsChecked(!isChecked);
  };

  const [moreField, setMoreField]: any = useState(false);
  const addMoreField = (event: any) => {
    event.preventDefault();
    setMoreField(!moreField);
  };

  const [show, setShow] = useState(false);

  const [checkBoxSelect1, setCheckBoxSelect1] = useState(false);
  const [checkBoxSelect2, setCheckBoxSelect2] = useState(false);
  const [checkBoxSelect3, setCheckBoxSelect3] = useState(false);
  const [checkBoxSelect4, setCheckBoxSelect4] = useState(false);
  const [checkBoxSelect5, setCheckBoxSelect5] = useState(false);
  const onCheckBoxInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    if (e.target.value === "Crebsol") {
      setCheckBoxSelect1(!checkBoxSelect1);
      setCheckBoxSelect2(false);
      setCheckBoxSelect3(false);
      setCheckBoxSelect4(false);
      setCheckBoxSelect5(false);
      setOrganizationError(false);
    }
    else if (e.target.value === "BUBT") {
      setCheckBoxSelect1(false);
      setCheckBoxSelect2(!checkBoxSelect2);
      setCheckBoxSelect3(false);
      setCheckBoxSelect4(false);
      setCheckBoxSelect5(false);
      setOrganizationError(false);
    }
    else if (e.target.value === "Tribal") {
      setCheckBoxSelect1(false);
      setCheckBoxSelect2(false);
      setCheckBoxSelect3(!checkBoxSelect3);
      setCheckBoxSelect4(false);
      setCheckBoxSelect5(false);
      setOrganizationError(false);
    }
    else if (e.target.value === "Leading") {
      setCheckBoxSelect1(false);
      setCheckBoxSelect2(false);
      setCheckBoxSelect3(false);
      setCheckBoxSelect4(!checkBoxSelect4);
      setCheckBoxSelect5(false);
      setOrganizationError(false);
    }
    else if (e.target.value === "Others") {
      setCheckBoxSelect1(false);
      setCheckBoxSelect2(false);
      setCheckBoxSelect3(false);
      setCheckBoxSelect4(false);
      setCheckBoxSelect5(!checkBoxSelect5);
      setOrganizationError(false);
    }
    else {
      setOrganizationError(true);
    }
  }

  const selectMobileBank = (e: any) => {
    console.log("e", e.target.value);
    setMobileBankError(false);
    setFormData({ ...formData, "mobileBank": e.target.value });
  }

  const [showModal, setShowModal] = useState(false);

  const handleCloseWithDisable = async () => {
    setShowModal(false);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestBody = JSON.stringify(formData);

    try {
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: requestBody,
        // redirect: 'follow'
      };

      let response = await fetch("https://amarkantho.revesoft.com:5557/register/", requestOptions)
      let status = response.status
      let responseBody = await response.json();
      setDisabled(false);
      console.log("Registration Response: ", responseBody);

      if (status === 200 || status === 201) {
        ToastSuccessMsg(responseBody["message"]);
        setTimeout(() => navigatePage(), 2000);
      } else {
        ToastFailedMsg(responseBody["message"]);
      }
    } catch (err: any) {
      setDisabled(false);
      console.log("ERROR ! ", err.message)
      ToastFailedMsg(`${err.message} ; কিছুক্ষণ পর আবার চেষ্টা করুন।`)
    }
  };
  const handleClose = () => { setShowModal(false); setDisabled(false) };
  const handleShow = () => setShowModal(true);

  return (
    <div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formData.phone}<br />{formData.mobileBank}</Modal.Title>
        </Modal.Header>
        <Modal.Body>নাম্বারটি যাচাই করুন। নাম্বার পরিবর্তনযোগ্য নয়।</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ফিরে যান
          </Button>
          <Button variant="primary" onClick={handleCloseWithDisable}>
            জমা দিন
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <div className="signupbg" style={{ overflow: "hidden" }}>
        <div className="container smdeviceview">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="signupbradcump">
                <a
                  href={
                    (process.env.REACT_APP_ROUTER_BASE || "") + "/"
                  }>
                  <img className="img-fluid" src={signup} alt="" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
              <div className="signupform shadow-dark2">
                <h4>নিবন্ধন করুন</h4>
                <div className="withcheckgroup" style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                  {/* <p>নিবন্ধনের উদ্দেশ্যে, আপনার বিবরণ প্রয়োজন।</p> */}
                  {organizationError ? <div className="error checkboxgroup" style={{ display: "flex", alignItems: "left" }}>
                    {/* <div className="lesstext">
                      <label className="container">
                        <input type="checkbox" value="Crebsol" checked={checkBoxSelect1} onChange={onCheckBoxInputChange} ></input>
                        <span className="leftm">ক্রেবসল</span>
                      </label>
                    </div> */}
                    <div className="lesstext">
                      <label className="container2">
                        <input type="checkbox" value="Tribal" checked={checkBoxSelect3} onChange={onCheckBoxInputChange} ></input>
                        <span className="leftm">আদিবাসী</span>
                      </label>
                    </div>
                    {/* <div className="lesstext">
                      <label className="container ">
                        <input type="checkbox" value="BUBT" checked={checkBoxSelect2} onChange={onCheckBoxInputChange} ></input>
                        <span className="leftm">বিইউবিটি</span>
                      </label>
                    </div> */}
                    <div className="lesstext">
                      <label className="container">
                        <input type="checkbox" value="Leading" checked={checkBoxSelect4} onChange={onCheckBoxInputChange} ></input>
                        <span className="leftm">লিডিং</span>
                      </label>
                    </div>
                    <div className="lesstext">
                      <label className="container ">
                        <input type="checkbox" value="Others" checked={checkBoxSelect5} onChange={onCheckBoxInputChange} ></input>
                        <span className="leftm">অন্যান্য</span>
                      </label>
                    </div>
                  </div> :
                    <div className="checkboxgroup" style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                      {/* <div className="lesstext">
                        <label className="container">
                          <input type="checkbox" value="Crebsol" checked={checkBoxSelect1} onChange={onCheckBoxInputChange} ></input>
                          <span className="leftm">ক্রেবসল</span>
                        </label>
                      </div> */}
                      <div className="lesstext">
                        <label className="container">
                          <input type="checkbox" value="Tribal" checked={checkBoxSelect3} onChange={onCheckBoxInputChange} ></input>
                          <span className="leftm">আদিবাসী</span>
                        </label>
                      </div>
                      {/* <div className="lesstext">
                        <label className="container ">
                          <input type="checkbox" value="BUBT" checked={checkBoxSelect2} onChange={onCheckBoxInputChange} ></input>

                          <span className="leftm">বিইউবিটি</span>
                        </label>
                      </div> */}
                      <div className="lesstext">
                        <label className="container">
                          <input type="checkbox" value="Leading" checked={checkBoxSelect4} onChange={onCheckBoxInputChange} ></input>
                          <span className="leftm">লিডিং</span>
                        </label>
                      </div>
                      <div className="lesstext">
                        <label className="container ">
                          <input type="checkbox" value="Others" checked={checkBoxSelect5} onChange={onCheckBoxInputChange} ></input>
                          <span className="leftm">অন্যান্য</span>
                        </label>
                      </div>
                    </div>}
                </div>

                {/* <h5 className="mowlictitle">মৌলিক তথ্য</h5> */}

                <Form
                  method="post"
                  onSubmit={(e: any) => e.preventDefault()}
                // noValidate
                // validated={validated}
                >
                  <div className="row mtopbottom">
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          নাম <span className="errorcolor">*</span>
                        </Form.Label>
                        <InputGroup size="sm">
                          <FormControl
                            className={
                              !nameError
                                ? "shadow-none samesize"
                                : "error samesize"
                            }
                            aria-label="Small"
                            name="fullname"
                            aria-describedby="inputGroup-sizing-sm"
                            value={formData.fullname}
                            onChange={onInputChange}
                            required
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          শৈশবকালীন এলাকা (২-১৫ বছর বয়স যেখানে কেটেছে)
                          <span className="errorcolor">*</span>
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control

                            className={
                              !areaError
                                ? "none samesize"
                                : "error samesize"
                            }
                            as="select"
                            name="elementarySchoolLocation"
                            onChange={onUserProfileInputChange}
                            /*value={
                              formData.userProfile.elementarySchoolLocation
                            }*/
                            required>

                            {places?.map((item: any, index: number) => (
                              <option key={index}>{item}</option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row mtopbottom">

                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          বয়স <span className="errorcolor">*</span>
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control
                            className={
                              !ageError
                                ? "none samesize"
                                : "error samesize"
                            }
                            as="select"
                            name="age"
                            onChange={onUserProfileInputChange}
                            required>
                            {agegroup?.map((item: any, index: number) => (
                              <option key={index}>{item}</option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          লিঙ্গ <span className="errorcolor">*</span>
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control
                            className={
                              !genderError
                                ? "none samesize"
                                : "error samesize"
                            }
                            as="select"
                            name="gender"
                            onChange={onUserProfileInputChange}
                            required>
                            {gender?.map((item: any, index: number) => (
                              <option key={index}>{item}</option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mtopbottom">
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          ইউজারনেম (ই-মেইল/ফোন){" "}
                          <span className="errorcolor">*</span>
                        </Form.Label>
                        <InputGroup size="sm">
                          <FormControl
                            className={
                              !emailError
                                ? "shadow-none samesize"
                                : "error samesize"
                            }
                            // type="text"
                            aria-label="Small"
                            value={formData.email}
                            name="email"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={onInputChange}
                          />
                        </InputGroup>
                        {emailError ? (
                          <span className="email-error-msg">সঠিক মেইল দিন</span>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          পাসওয়ার্ড <span className="errorcolor">*</span>
                        </Form.Label>
                        <InputGroup size="sm">
                          <FormControl
                            className={
                              !passwordError
                                ? "shadow-none samesize"
                                : "passerror samesize"
                            }
                            // type="text"
                            aria-label="Small"
                            name="password"
                            type="password"
                            aria-describedby="inputGroup-sizing-sm"
                            value={formData.password}
                            onChange={onInputChange}
                            required
                          />
                        </InputGroup>
                        {passwordError ? (
                          <span className="email-error-msg">
                            পাসওয়ার্ড ৬ অক্ষর বা তার বেশি
                          </span>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>ফোন নাম্বার <span className="errorcolor">*</span></Form.Label>
                        <InputGroup size="sm" className="mb-3">
                          <FormControl
                            className="none samesize"
                            name="phone"
                            aria-describedby="inputGroup-sizing-sm"
                            value={formData.phone}
                            onChange={onInputChange}
                            aria-label="Small"
                            required
                          >
                          </FormControl>
                        </InputGroup>
                        {phoneError ? (
                          <span className="email-error-msg">সঠিক ফোন নাম্বার দিন</span>
                        ) : null}
                      </Form.Group>
                      <div onChange={selectMobileBank}>
                        <span style={{ marginRight: "10px" }}><input type="radio" value="Bkash" name="gender" required /> বিকাশ </span>
                        <span style={{ marginRight: "10px" }}><input type="radio" value="Nogad" name="gender" required /> নগদ</span>
                        <span style={{ marginRight: "10px" }}><input type="radio" value="Rocket" name="gender" required /> রকেট</span>
                        <span style={{ marginRight: "10px" }}><input type="radio" value="MobileRecharge" name="gender" required /> রিচার্জ</span>
                      </div>
                      {mobileBankError ? (
                        <span className="email-error-msg">
                          সঠিক মোবাইল ব্যাংক নির্বাচন করুন</span>
                      ) : null}
                    </div>
                    {/* <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center">
                      
                    </div> */}
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          শিক্ষা
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control
                            className="none samesize"
                            as="select"
                            name="education_level"
                            value={formData.education_level}
                            onChange={onUserProfileInputChange}
                          >
                            {education_level?.map(
                              (item: any, index: number) => (
                                <option key={index}>{item}</option>
                              )
                            )}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          অর্থনৈতিক অবস্থা
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control
                            className="none samesize"
                            as="select"
                            name="economic_situation"
                            value={formData.economic_situation}
                            onChange={onUserProfileInputChange}
                          >
                            {economic_situation?.map(
                              (item: any, index: number) => (
                                <option key={index}>{item}</option>
                              )
                            )}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group>
                        <Form.Label style={{ display: "inline-block", textAlign: "left", width: "100%" }}>
                          পেশা
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control
                            className="none samesize"
                            as="select"
                            name="profession"
                            onChange={(e: any) => onUserProfileInputChange(e)}
                            value={formData.profession}
                          >
                            {profession?.map((item: any, index: number) => (
                              <option key={index}>{item}</option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </div>
                    {/*<div className="col-12 col-sm-6">*/}
                    {/*  <Form.Group>*/}
                    {/*    <Form.Label>*/}
                    {/*      বয়স <span className="errorcolor">*</span>*/}
                    {/*    </Form.Label>*/}
                    {/*    <InputGroup size="sm">*/}
                    {/*      <Form.Control*/}
                    {/*        className="none samesize"*/}
                    {/*        as="select"*/}
                    {/*        name="age"*/}
                    {/*        onChange={onUserProfileInputChange}*/}
                    {/*        required>*/}
                    {/*        {agegroup?.map((item: any, index: number) => (*/}
                    {/*          <option key={index}>{item}</option>*/}
                    {/*        ))}*/}
                    {/*      </Form.Control>*/}
                    {/*    </InputGroup>*/}
                    {/*  </Form.Group>*/}
                    {/*</div>*/}
                  </div>

                  {/*<div className="row mtopbottom">*/}
                  {/*  <div className="col-12 col-sm-6">*/}
                  {/*    <Form.Group>*/}
                  {/*      <Form.Label>এনআইডি/জন্ম নিবন্ধন নং</Form.Label>*/}
                  {/*      <InputGroup size="sm">*/}
                  {/*        <FormControl*/}
                  {/*          className={*/}
                  {/*            "samesize"*/}
                  {/*            // !nameError*/}
                  {/*            //   ? "shadow-none samesize"*/}
                  {/*            //   : "error samesize"*/}
                  {/*          }*/}
                  {/*          // aria-label="Small"*/}
                  {/*          // name="firstname"*/}
                  {/*          // aria-describedby="inputGroup-sizing-sm"*/}
                  {/*          // value={formData.firstname}*/}
                  {/*          // onChange={onInputChange}*/}
                  {/*          // required*/}
                  {/*        />*/}
                  {/*      </InputGroup>*/}
                  {/*    </Form.Group>*/}
                  {/*  </div>*/}
                  {/*  <div className="col-12 col-sm-6">*/}
                  {/*    <Form.Group>*/}
                  {/*      <Form.Label>*/}
                  {/*        লিঙ্গ <span className="errorcolor">*</span>*/}
                  {/*      </Form.Label>*/}
                  {/*      <InputGroup size="sm">*/}
                  {/*        <Form.Control*/}
                  {/*          className="none samesize"*/}
                  {/*          as="select"*/}
                  {/*          name="gender"*/}
                  {/*          onChange={onUserProfileInputChange}*/}
                  {/*          required>*/}
                  {/*          {gender?.map((item: any, index: number) => (*/}
                  {/*            <option key={index}>{item}</option>*/}
                  {/*          ))}*/}
                  {/*        </Form.Control>*/}
                  {/*      </InputGroup>*/}
                  {/*    </Form.Group>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="row mtopbottom">
                    <div className="col">
                      {/* <h5 className="mowlictitle">স্পিকার প্রোফাইল (ঐচ্ছিক)</h5>
                      <div className="row mtopbottom">
                        <div className="col-12 col-sm-6">
                          <Form.Group>
                            <Form.Label>
                              শিক্ষা
                            </Form.Label>
                            <InputGroup size="sm">
                              <Form.Control
                                className="none samesize"
                                as="select"
                                name="education_level"
                                value={formData.education_level}
                                onChange={onUserProfileInputChange}
                              >
                                {education_level?.map(
                                  (item: any, index: number) => (
                                    <option key={index}>{item}</option>
                                  )
                                )}
                              </Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-6">
                          <Form.Group>
                            <Form.Label>
                              অর্থনৈতিক অবস্থা
                            </Form.Label>
                            <InputGroup size="sm">
                              <Form.Control
                                className="none samesize"
                                as="select"
                                name="economic_situation"
                                value={formData.economic_situation}
                                onChange={onUserProfileInputChange}
                              >
                                {economic_situation?.map(
                                  (item: any, index: number) => (
                                    <option key={index}>{item}</option>
                                  )
                                )}
                              </Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row mtopbottom">
                        <div className="col-12 col-sm-6">
                          <Form.Group>
                            <Form.Label>
                              পেশা
                            </Form.Label>
                            <InputGroup size="sm">
                              <Form.Control
                                className="none samesize"
                                as="select"
                                name="profession"
                                onChange={(e: any) => onUserProfileInputChange(e)}
                                value={formData.profession}
                              >
                                {profession?.map((item: any, index: number) => (
                                  <option key={index}>{item}</option>
                                ))}
                              </Form.Control>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div> */}

                      <div className="row mtopbottom">

                        {/* <div className="col-12 col-sm-6">
                              <Form.Group>
                                <Form.Label>শিক্ষাগত যোগ্যতা </Form.Label>
                                <InputGroup size="sm">
                                  <Form.Control
                                    as="select"
                                    name="education_level"
                                    value={
                                      formData.userProfile.education_level
                                    }
                                    onChange={onUserProfileInputChange}
                                    required>
                                    {education_level?.map(
                                      (item: any, index: number) => (
                                        <option key={index}>{item}</option>
                                      )
                                    )}
                                  </Form.Control>
                                </InputGroup>
                              </Form.Group>
                            </div> */}
                      </div>

                      <div>
                        {/* <details open={moreField}>
                          <summary onClick={addMoreField}>
                            <strong>
                              {" "}
                              <AiFillCaretRight />
                              আরও যোগ করুন{" "}
                            </strong>
                          </summary>
                          <div className="row mtopbottom">
                            <div className="col-12 col-sm-6">
                              <Form.Group>
                                <Form.Label>অর্থনৈতিক অবস্থা</Form.Label>
                                <InputGroup size="sm">
                                  <Form.Control
                                    as="select"
                                    name="economic_situation"
                                    value={
                                      formData.userProfile.economic_situation
                                    }
                                    onChange={onUserProfileInputChange}
                                    required>
                                    {economic_situation?.map(
                                      (item: any, index: number) => (
                                        <option key={index}>{item}</option>
                                      )
                                    )}
                                  </Form.Control>
                                </InputGroup>
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Form.Group>
                                <Form.Label>শিক্ষাগত যোগ্যতা </Form.Label>
                                <InputGroup size="sm">
                                  <Form.Control
                                    as="select"
                                    name="education_level"
                                    value={
                                      formData.userProfile.education_level
                                    }
                                    onChange={onUserProfileInputChange}
                                    required>
                                    {education_level?.map(
                                      (item: any, index: number) => (
                                        <option key={index}>{item}</option>
                                      )
                                    )}
                                  </Form.Control>
                                </InputGroup>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row mtopbottom">
                            <div className="col-12 col-sm-6">
                              <Form.Group>
                                <Form.Label>পেশা </Form.Label>
                                <InputGroup size="sm">
                                  <Form.Control
                                    as="select"
                                    name="profession"
                                    onChange={(e) =>
                                      onUserProfileInputChange(e)
                                    }
                                    value={formData.userProfile.profession}
                                    required>
                                    {profession?.map(
                                      (item: any, index: number) => (
                                        <option key={index}>{item}</option>
                                      )
                                    )}
                                  </Form.Control>
                                </InputGroup>
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Form.Group>
                                <Form.Label>স্থায়ী ঠিকানা </Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                  <FormControl
                                    as="select"
                                    name="permanentaddress"
                                    value={
                                      formData.userProfile.permanentaddress
                                    }
                                    onChange={onUserProfileInputChange}
                                    required>
                                    {address?.map(
                                      (item: any, index: number) => (
                                        <option key={index}>{item}</option>
                                      )
                                    )}
                                  </FormControl>
                                </InputGroup>
                              </Form.Group>
                            </div>
                          </div>
                        </details> */}
                      </div>
                    </div>
                  </div>

                  <div className="row mtopbottom">
                    <div className="col-12 col-sm-6 mt-3">
                      <div className="form-check">
                        <label className="container containercenter">
                          <input
                            id="c1"
                            type="checkbox"
                            className="form-check-input ongikercheckbox"
                            checked={isChecked}
                            onChange={handleOnChange}
                            // onClick="checkMe(this.checked);"

                            onClick={() => setShow(!show)}
                          />
                          <span className="ongicernamasugn pt-3">সম্মতি</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mt-3">
                      {show ? (
                        <button
                          disabled={disabled}
                          className="modaljomadin mjomadinbutton"
                          onClick={onFormSubmit}
                          style={{
                            float: "right",
                            height: "40px",
                          }}>
                          <span>
                            <img src={upload} alt="upload" />
                          </span>
                          জমা দিন
                        </button>
                      ) : (
                        <button
                          disabled
                          className="modaljomadin mjomadinbuttonhide"
                          onClick={onFormSubmit}
                          style={{
                            float: "right",
                            height: "40px",
                          }}>
                          <span>
                            <img src={upload} alt="upload" />
                          </span>
                          জমা দিন
                        </button>
                      )}
                    </div>
                    {/*<div className="col">*/}
                    {/*    <div className="submitbutton">*/}

                    {/*        <button className="modaljomadin" onClick={onFormSubmit}>*/}
                    {/*            <img src={jomadin} alt=""/>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row mtopbottom">
                      {/*<div className="col-12 col-sm-6">*/}
                      {/*    <div className="loginbutton">*/}
                      {/*        <strong>কোনো অ্যাকাউন্ট নেই? <button style={{*/}
                      {/*            border:"2px solid green",*/}
                      {/*            borderRadius:"8px",*/}
                      {/*            background:"green",*/}
                      {/*            width:"90px",*/}
                      {/*            textAlign:"center",*/}
                      {/*            marginLeft:"0px"*/}

                      {/*        }} >নিবন্ধন করুন </button></strong>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                      {/* <div className="col"> */}
                      {/* <button
                        className="modaljomadin mjomadinbutton"
                        onClick={onFormSubmit}
                        style={{ float: "right", height: "40px" }}>
                        <span>
                          <img src={upload} alt="upload" />
                        </span>
                        জমা দিন
                      </button> */}
                      {/* </div> */}
                    </div>
                  </div>
                </Form>

                <div className="row mtopbottom checktextcontent">
                  <div className="col">
                    <div className="opendetails">
                      {/* <details open={open} onClick={onToggle}> */}
                      {/* <summary>
                          <strong>
                            <AiFillCaretRight />
                            বিস্তারিত
                          </strong>
                        </summary> */}
                      {show ? (
                        <p>
                          গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের ডাক, টেলিযোগাযোগ ও
                          তথ্য প্রযুক্তি মন্ত্রণালয়ের অধীনে গবেষণা ও উন্নয়নের
                          মাধ্যমে তথ্য প্রযুক্তিতে বাংলা ভাষা সমৃদ্ধিকরণ
                          প্রকল্পের অধীনে রিভ সিস্টেমস লিমিটেড (Reve Systems
                          Ltd.) বাংলা স্পিচ টু টেক্সট (এসটিটি) ও টেক্সট টু স্পিচ
                          (টিটিএস) সফটওয়্যার তৈরির কাজ করছে। এই প্রকল্পের লক্ষ্য
                          ও উদ্দেশ্য হচ্ছে, আন্তর্জাতিক পরিসরে নেতৃস্থানীয় ভাষা
                          হিসেবে বাংলাকে প্রতিষ্ঠিত করা। প্রকল্পটি বাস্তবায়নের
                          লক্ষ্যে বাংলাদেশের বিভিন্ন অঞ্চল থেকে ভাষাতাত্ত্বিক
                          উপাত্ত সংগ্রহে মাঠ পর্যায়ের গবেষণা পরিচালিত হচ্ছে। এই
                          প্রকল্পের আওতায় সংগৃহীত উপাত্ত অন্য কোনো প্রকল্পে বা
                          অন্য কোনো উদ্দেশ্যে ব্যবহৃত হবে না। এই ডেটাসেটে
                          তথ্যপ্রদানকারীর ব্যক্তিগত তথ্য যেমন নাম, ঠিকানা
                          ইত্যাদি প্রকাশ করা হবে না। তবে বয়স, নারী/পুরুষ,
                          আঞ্চলিক/স্থানের মেটাডেটা থাকবে। ভবিষ্যতে এই ডেটা
                          মন্ত্রণালয় প্রচলিত বিধি, রীতি ও গবেষণার আইন অনুসারে
                          প্রকাশ করতে পারবে। অতএব, প্রকল্পটি বাস্তবায়নের
                          উদ্দেশ্যে আমি একজন অংশগ্রহণকারী হিসেবে সহযোগিতা করতে
                          সম্মতি প্রদান এবং এই প্রকল্পের সর্বাঙ্গীণ সাফল্য কামনা
                          করছি।
                        </p>
                      ) : null}

                      {/* </details> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-areabottom">
                <ul className="footer-item">
                  <li className="developed">
                    <span>
                      Designed & Developed by
                      <a
                        style={{
                          color: "blue",
                        }}
                        href="https://www.revesoft.com/">
                        REVE Systems
                      </a>
                    </span>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li className="copyclass">
                    <a>mail: news4reve@gmail.com</a>
                    <a href="https://www.facebook.com/Amar-Kontho-107355312220670"><IconContext.Provider
                      value={{ color: '#1877F2', size: '28px' }}
                    ><AiFillFacebook /></IconContext.Provider></a>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li>
                    <a>
                      <img
                        className="img-fluid"
                        src={bangla_logo}
                        alt="bangla"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={bccl_logo} alt="BCC" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={ict_logo} alt="ict" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}