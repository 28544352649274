import React from 'react'
import "../css/modal.css"

const Modal = ({ closeModal, haActionLink}) => {
  return (
    <div className="modalBackground" >
      <div className="modalContainer">
        {/* <button onClick={() => closeModal(false)} className="modalclose">X</button> */}
        <div className="title"></div>
        <div className="body">
          <p className="getpermission">কথোপকথন থেকে বের হয়ে যেতে চান ?</p>
        </div>
        <div className="footer">
          <div className="hagroup">
            <a href={haActionLink}> <button className="habtn" >হ্যা</button></a>
            <span className="hatext">রেকর্ডটি জমা হবে </span>
          </div>
          <div className="nagroup">
            <button className="nabtn" onClick={() => closeModal(false)}>
              না
            </button>
            <span className="natext">রেকর্ড চালিয়ে যান </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal