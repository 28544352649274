import React,{useState} from 'react'
import { useParams } from 'react-router-dom';
import Footer from "./assets/js/Footer";
import Header from "./Header";
import "./assets/css/indexBolunShunun.css"
import LectureComponent from './Lecture.component';

export default function IndexLecture() {
    let { id }: any = useParams();

    return (
        <div className='readbg'>
            <Header />
            <LectureComponent />
            <Footer />
            </div>
    )
}