import React from "react";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl_logo from "./images/bccl_ogo.png";
import ict_logo from "./images/ict_logo.png";
// import "./home.scss";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import mulpata from "./images/stts/mulpata.png";
import MenuComponent from "./Menu.Component";
import Footer from "./assets/js/Footer";
import Header from "./Header";
import { Container } from "react-bootstrap";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Noto Serif Bengali, serif',
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const rejectRows = [
    { 'reason': 'স্ক্রিপ্ট-স্পিচে অমিল', 'description': 'এক শব্দের স্থানে অন্য কোনো শব্দ পড়েছে / কোনো শব্দ বাদ দিয়েছে বা যোগ করেছে।' },
    { 'reason': 'অপ্রমিত উচ্চারণ', 'description': 'এক বা একাধিক শব্দ অপ্রমিতভাবে পড়া হয়েছে।' },
    { 'reason': 'অস্পষ্ট', 'description': 'এক বা একাধিক শব্দ / শব্দের ধ্বনিমূল স্পষ্টভাবে বোঝা যাচ্ছে না।' },
    { 'reason': 'শুধু নয়েজ', 'description': 'স্ক্রিপ্ট পড়া হয়নি শুধুই পারিপার্শ্বিক নয়েজ / আওয়াজ রেকর্ড হয়েছে।' },
    { 'reason': 'আংশিক রেকর্ড', 'description': 'সম্পূর্ণ স্ক্রিপ্ট পড়া হয়নি।' },
    { 'reason': 'অন্যান্য', 'description': 'উপরে উল্লিখিত ধরনের বাইরে যেকোনো ধরনের ভুল।' }
];

const acceptRows = [
    { 'reason': 'সঠিক', 'description': 'সঠিক ও স্পষ্ট উচ্চারণ এবং স্বাভাবিক পরিবেশ বজায় রয়েছে।' },
    { 'reason': 'শ্বাসের শব্দ', 'description': 'পড়ার সময় পাঠকের নিঃশ্বাসের শব্দও রেকর্ড হয়েছে।' },
    { 'reason': 'ভলিউম কম', 'description': 'খুব আস্তে শোনা যাচ্ছে।' },
    { 'reason': 'বানান ভুল', 'description': 'স্ক্রিপ্টে ভুল বানানে লেখা আছে এবং পাঠক ভুল বানান অনুসরণ করেই পড়েছেন।' },
    { 'reason': 'অন্য আওয়াজ', 'description': 'পাঠকের পড়ার সময় অন্য কোনো আওয়াজ বা কথা রেকর্ড হয়ে থাকলে।' }
];

export default function ListeningRulesPage() {
    return (
        <>
            <Header />
            {/* <Container>
                <div><ol>
                    <li style={{listStyle:"bengali"}}>বাক্যের প্রতিটি শব্দ প্রমিত রূপে ও ধারাবাহিকভাবে পড়া হলে ‘হ্যাঁ’ নির্বাচন করুন। ‘হ্যাঁ’ নির্বাচনে যে বিষয়গুলো বিবেচনা করবেন-
                        <ul>
                            <li> সঠিক : সঠিক ও স্পষ্ট উচ্চারণ এবং স্বাভাবিক পরিবেশ বজায় রয়েছে।</li>
                            <li> শ্বাসের শব্দ : পড়ার সময় পাঠকের নিঃশ্বাসের শব্দও রেকর্ড হয়েছে।</li>
                            <li> ভলিউম কম : খুব আস্তে শোনা যাচ্ছে।</li>
                            <li> বানান ভুল : স্ক্রিপ্টে ভুল বানানে লেখা আছে এবং পাঠক ভুল বানান অনুসরণ করেই পড়েছেন।</li>
                            <li> অন্যদের কথা : মূল পাঠকের পড়ার সময় অন্য ব্যক্তির কথা রেকর্ড হয়েছে এবং সেই ব্যক্তির শব্দগুলো বোঝা যাচ্ছে।</li>
                            <li> অন্য আওয়াজ : এমন আওয়াজ / শব্দ আছে যা বাক্য পড়ার আওয়াজের তুলনায় 				বেশি।</li>
                        </ul>
                    </li>
                    <li style={{listStyle:"bengali"}}>বাক্যের প্রতিটি শব্দ প্রমিত রূপে ও ধারাবাহিকভাবে পড়া না হলে ‘না’ নির্বাচন করুন। ‘না’ নির্বাচনে যে বিষয়গুলো বিবেচনা করবেন-
                        <ul>
                            <li> স্ক্রিপ্ট -স্পিচে অমিল : এক শব্দের স্থানে অন্য কোনো শব্দ পড়েছে / কোনো শব্দ বাদ দিয়েছে বা যোগ করেছে।</li>
                            <li> অপ্রমিত উচ্চারণ : এক বা একাধিক শব্দ অপ্রমিতভাবে পড়া হয়েছে।  </li>
                            <li> অস্পষ্ট : এক বা একাধিক শব্দ / শব্দের ধ্বনিমূল স্পষ্টভাবে বোঝা যাচ্ছে না।</li>
                            <li> শুধু নয়েজ : স্ক্রিপ্ট পড়া হয়নি শুধুই পারিপার্শ্বিক নয়েজ / আওয়াজ রেকর্ড হয়েছে।  </li>
                            <li> আংশিক রেকর্ড : সম্পূর্ণ স্ক্রিপ্ট পড়া হয়নি।</li>
                            <li> অন্যান্য : উপরে উল্লিখিত ধরনের বাইরে যেকোনো ধরনের ভুল।</li>
                        </ul>
                    </li>

                </ol></div>
            </Container> */}
            <Box
                // sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 4 }}
                sx={{ flexGrow: 1, maxWidth: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}
                        // sx={{ width: '45%', maxWidth: '50%' }}
                        >
                            <h3 className="p-0 m-0" style={{ fontFamily: 'Noto Serif Bengali, serif', fontWeight: 'bolder', backgroundColor: 'rgb(237, 247, 237)' }}>হ্যাঁ</h3>
                            <Alert sx={{ fontFamily: 'Noto Serif Bengali, serif', fontSize: '112%', fontWeight: 'bold' }} severity="success">বাক্যের প্রতিটি শব্দ প্রমিত রূপে ও ধারাবাহিকভাবে পড়া হলে ‘হ্যাঁ’ নির্বাচন করুন।<br />‘হ্যাঁ’ নির্বাচনে যে বিষয়গুলো বিবেচনা করবেন -</Alert>
                            <TableContainer component={Paper} sx={{ width: '100%' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>REASON</StyledTableCell>
                                            <StyledTableCell>DESCRIPTION</StyledTableCell>
                                            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {acceptRows.map((row) => (
                                            <StyledTableRow
                                                key={row.reason}
                                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                    {row.reason}
                                                </StyledTableCell>
                                                <StyledTableCell align="justify" >{row.description}</StyledTableCell>
                                                {/* <TableCell align="right">{row.fat}</TableCell> */}
                                                {/* <TableCell align="right">{row.carbs}</TableCell> */}
                                                {/* <TableCell align="right">{row.protein}</TableCell> */}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Stack spacing={2}
                        // sx={{ width: '45%', maxWidth: '50%' }}
                        >
                            <h3 className="p-0 m-0" style={{ fontFamily: 'Noto Serif Bengali, serif', fontWeight: 'bolder', backgroundColor: 'rgb(253, 237, 237)' }}>না</h3>
                            <Alert severity="error" sx={{ fontFamily: 'Noto Serif Bengali, serif', fontSize: '112%', fontWeight: 'bold' }}>বাক্যের প্রতিটি শব্দ প্রমিত রূপে ও ধারাবাহিকভাবে পড়া না হলে ‘না’ নির্বাচন করুন।<br />‘না’ নির্বাচনে যে বিষয়গুলো বিবেচনা করবেন -</Alert>
                            <TableContainer component={Paper} sx={{ width: '100%' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>REASON</StyledTableCell>
                                            <StyledTableCell>DESCRIPTION</StyledTableCell>
                                            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rejectRows.map((row) => (
                                            <StyledTableRow
                                                key={row.reason}
                                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                    {row.reason}
                                                </StyledTableCell>
                                                <StyledTableCell align="justify">{row.description}</StyledTableCell>
                                                {/* <TableCell align="right">{row.fat}</TableCell> */}
                                                {/* <TableCell align="right">{row.carbs}</TableCell> */}
                                                {/* <TableCell align="right">{row.protein}</TableCell> */}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    );
}
