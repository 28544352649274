import React, { useState, useEffect, PureComponent } from "react";
// import DatePicker from "react-datepicker"; 

import "./reportDashboard.css";
import "../user-dashboard/userdashboard.css";
// import MenuComponent from "./Menu.Component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import bangla_logo from "../images/Bangla_Logo.png";
import bccl_logo from "../images/bccl_ogo.png";
import ict_logo from "../images/ict_logo.png";
import footertop from "../images/footertop.png";
import CrossIcon from '../assets/img/cross_icon.png';
import { getTotalAudio, getTotalTime, getTotalListeningTime, getDayWiseDuration } from "../crowd_source.service";
import { getLoggedInUserId, isLoggedIn } from "../../../common/http";
import { months } from "moment";
import { Button } from "react-bootstrap";
import { getSpeechReport, getUserReport } from "../crowd_source.service";


export default function ReportDashboard() {

    const [speech_report, setSpeechReport]:any = useState();
    const [user_report, setUserReport]:any = useState();
    const [input_date, setInputDate]:any = useState({
        "start_date": "",
        "end_date": ""
    });
    const [isReportVisible, setIsReportVisible]:any = useState(false);


    console.log("date: ", new Date().toISOString().slice(0,10));
    

    useEffect(() => {
        console.log('Do something after counter has changed', input_date);
        if(input_date.start_date && input_date.end_date) {

            getSpeechReport(input_date)
            .then(res => res.json())
            .then(data => {
                setSpeechReport(data)
                console.log(data)
            })
            .catch((error) => {
                console.log("error: ", error);
            })
           
            getUserReport(input_date)
            .then(res => res.json())
            .then(data => {
                setUserReport(data)
                console.log(data)
            })
            .catch((error) => {
                console.log("error: ", error);
            })

        }

        console.log("input 0bj: ", input_date); 
     }, [input_date]);

     useEffect ( () => {
        console.log("speech-report2: ", speech_report);
     }, [speech_report]);

     useEffect ( () => {
        console.log("user-report2: ", user_report);
     }, [user_report]);

    // function sleep(ms) {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // } 

    const onSubmitChange = async(e:any) => {
        e.preventDefault();
        //@ts-ignore
        setInputDate({...input_date, 'start_date': document.getElementById('startDate').value, 'end_date': document.getElementById('endDate').value});
        //setInputDate({...input_date, 'start_date': e.target.value, 'end_date': e.target.value});
        setIsReportVisible(true);
    }

    return (

        <div className="reportdashboard" style={{
            marginTop: "-50px"
        }}>
            <div className="container smdeviceview mt-5">
                <div className="row">
                    <div className="col-sm-12 col-md-1 col-lg-2 col-xl-1">
                        <div className="leftmenuarea">
                            <div className="borderright">
                                <div className="borderacitve"></div>
                                <nav>
                                    <ul>
                                        <li>
                                            <a href="" className="active">
                                                ড্যাশবোর্ড
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-7 col-xl-8">
                        <div className="contentarea">
                                <div className="toptitle">
                                    <div className="lefttitle">
                                        <h4>তারিখ নির্বাচন করুন </h4>
                                    </div>
                                </div>

                                <form onSubmit={onSubmitChange}>
                                    <div className="input-area">
                                        <div className="row">
                                            <div className="lefttitle">
                                                <p>শুরুর তারিখ: </p>
                                            </div>
                                            <div className="input-text">
                                                <input type="date" id="startDate" max={new Date().toISOString().slice(0,10)}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="lefttitle">
                                                <p>শেষ তারিখ:  </p>
                                            </div>
                                            <div className="input-text">
                                                <input type="date" id="endDate" max={new Date().toISOString().slice(0,10)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <Button type="submit" variant="btn btn-outline-primary "> জমা দিন</Button>
                                </form>
                            </div>

                        { isReportVisible ? (
                            <div className="table-modal">
                                    <div className="close-icon">
                                        <img src={CrossIcon} alt="cross-icon" onClick={() => setIsReportVisible(false)}/>
                                    </div>
                                    <div className="contentarea">
                                        <div className="toptitle">
                                            <div className="lefttitle">
                                                <h4>রেকর্ডকৃত সময়</h4>
                                            </div>
                                        </div>

                                        <div className="table-area row">
                                            <table className="table table-striped table-dark my-table">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">
                                                            Action
                                                        </th>
                                                        <th scope="col">
                                                            Total time (in seconds)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        speech_report ? (speech_report.data.map( (item:any, index:any) => (
                                                            <tr key={index}>
                                                                <td>
                                                                {item.action}
                                                                </td>
                                                                <td>
                                                                    {item.total_seconds.toFixed(2)}  
                                                                </td>
                                                            </tr>
                                                        ))):null
                                                    }
                                                    {/* <tr>
                                                        <td>
                                                            Speaking
                                                        </td>
                                                        <td>
                                                            129192.22
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Listening
                                                        </td>
                                                        <td>
                                                            129192.22
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="contentarea">
                                            <div className="toptitle">
                                                <div className="lefttitle">
                                                    <h4>রেকর্ডকৃত ব্যবহারকারীর সংখ্যা </h4>
                                                </div>
                                            </div>

                                            <div className="table-area row">
                                            <table className="table table-striped table-dark my-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            Action
                                                        </th>
                                                        <th scope="col">
                                                            Count
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        user_report ? ( user_report.data.map( (item:any, index:any) => (
                                                            <tr key={index}>
                                                                <td>
                                                                {item.action}
                                                            </td>
                                                            <td>
                                                                {item.count}  
                                                            </td>
                                                            </tr>
                                                        ))):null
                                                    }
                                                    {/* <tr>
                                                        <td>
                                                            Speaker
                                                        </td>
                                                        <td>
                                                            193
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Listener
                                                        </td>
                                                        <td>
                                                            36
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                            </div>


                                    </div>
                                </div>
                            ):null
                        }
                    </div>
                </div>
            </div>

            {/* ============================== Footer Content start here ===================== */}

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-areabottom">
                            <ul className="footer-item">
                                <li className="developed">
                                    <span>
                                        Designed &#38; Developed by
                                        <a
                                            style={{
                                                color: "blue",
                                            }}
                                            href="https://www.revesoft.com/">
                                            REVE Systems
                                        </a>
                                    </span>
                                </li>
                            </ul>
                            <ul className="footer-item">
                                <li className="copyclass">
                                    <a>&copy; 2015-2021 All Rights Reserved</a>
                                </li>
                            </ul>
                            <ul className="footer-item">
                                <li>
                                    <a>
                                        <img className="img-fluid" src={bangla_logo} alt="bangla" />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <img className="img-fluid" src={bccl_logo} alt="BCC" />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <img className="img-fluid" src={ict_logo} alt="ict" />
                                    </a>
                                </li>
                            </ul>
                            <div className="footerleftimg">
                                <img src={footertop} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ============================== Footer Content start here ===================== */}
        </div>
    );
};

