import React from "react";
import mulpata from "./images/stts/mulpata.png";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import MenuComponent from "./Menu.Component";

export default function AboutPage() {
  return (
    <div className="aboutbody">
      <MenuComponent />

      <div
        style={{
          marginTop: "20px",
        }}
        className="container smdeviceview">
        <div className="row">
          <div className="col-md-12">
            <div className="jachybradcamp">
              <a
                href={
                  (process.env.REACT_APP_ROUTER_BASE || "") + "/reve-crowd-bn"
                }>
                <img src={mulpata} alt="" />
              </a>
            </div>
          </div>

          {/* <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 col-xxl-7"></div> */}
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <div className="aboutmedetails">
              <h5 className="kenoamerkonto">কেন আমার কণ্ঠ</h5>
              {/* <div className="rightsidebar rightshadow"> */}
              <div className="readblock rightshadow">
                <div className="obodanareaonly">
                  <div className="mandondocard">
                    <div className="msinglecard">
                      <p>
                        গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের ডাক, টেলিযোগাযোগ ও তথ্য
                        প্রযুক্তি মন্ত্রণালয়ের অধীনে গবেষণা ও উন্নয়নের মাধ্যমে
                        তথ্য প্রযুক্তিতে বাংলা ভাষা সমৃদ্ধিকরণ প্রকল্পের অধীনে
                        রিভ সিস্টেমস লিমিটেড (Reve Systems Ltd.) বাংলা স্পিচ টু
                        টেক্সট (এসটিটি) ও টেক্সট টু স্পিচ (টিটিএস) সফটওয়্যার
                        তৈরির কাজ করছে।
                      </p>
                      <p>
                        এই প্রকল্পের লক্ষ্য ও উদ্দেশ্য হচ্ছে, আন্তর্জাতিক পরিসরে
                        নেতৃস্থানীয় ভাষা হিসেবে বাংলাকে প্রতিষ্ঠিত করা।
                        প্রকল্পটি বাস্তবায়নের লক্ষ্যে বাংলাদেশের বিভিন্ন অঞ্চল
                        থেকে ভাষাতাত্ত্বিক উপাত্ত সংগ্রহে মাঠ পর্যায়ের গবেষণা
                        পরিচালিত হচ্ছে। এই প্রকল্পের আওতায় সংগৃহীত উপাত্ত অন্য
                        কোনো প্রকল্পে বা অন্য কোনো উদ্দেশ্যে ব্যবহৃত হবে না। এই
                        ডেটাসেটে তথ্যপ্রদানকারীর ব্যক্তিগত তথ্য যেমন নাম, ঠিকানা
                        ইত্যাদি প্রকাশ করা হবে না। তবে বয়স, নারী/পুরুষ,
                        আঞ্চলিক/স্থানের মেটাডেটা থাকবে। ভবিষ্যতে এই ডেটা
                        মন্ত্রণালয় প্রচলিত বিধি, রীতি ও গবেষণার আইন অনুসারে
                        প্রকাশ করতে পারবে।
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-areabottom">
              <ul className="footer-item">
                <li className="developed">
                  <span>
                    {" "}
                    Designed & Developed by{" "}
                    <a
                      style={{
                        color: "blue",
                      }}
                      href="https://www.revesoft.com/">
                      REVE Systems
                    </a>
                  </span>
                </li>
              </ul>
              <ul className="footer-item">
                <li className="copyclass">
                  <a>&copy; 2015-2021 All Rights Reserved</a>
                </li>
              </ul>
              <ul className="footer-item">
                <li>
                  <a>
                    <img className="img-fluid" src={bangla_logo} alt="bangla" />
                  </a>
                </li>
                <li>
                  <a>
                    <img className="img-fluid" src={bccl} alt="BCC" />
                  </a>
                </li>
                <li>
                  <a>
                    <img className="img-fluid" src={ict} alt="ict" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
