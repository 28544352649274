import React from "react";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl_logo from "./images/bccl_ogo.png";
import ict_logo from "./images/ict_logo.png";
import "./home.scss";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import mulpata from "./images/stts/mulpata.png";
import MenuComponent from "./Menu.Component";

export default function ReadingRulesPage() {
  return (
    <div>
      {/* <div className="detailstext"> */}
      <div className="aboutbody">
        <MenuComponent />

        <div className="container smdeviceview">
          <div className="row">
            <div className="col-md-12">
              <div className="jachybradcamp">
                <a
                  href={
                    (process.env.REACT_APP_ROUTER_BASE || "") + "/reve-crowd-bn"
                  }>
                  <img src={mulpata} alt="" />
                </a>
              </div>

              <div className="readingruleshow">
                <h3>পড়ার নিয়মাবলি</h3>

                <div className="readblock">
                  <ul
                    style={{
                      listStyleType: "circle",
                    }}>
                    <li className="rule-list">
                      স্ক্রিনে আসা বাক্যগুলোর প্রতিটি শব্দ সঠিকভাবে (প্রমিত
                      রূপে) ও ধারাবাহিকতা রক্ষা করে পড়তে হবে।
                    </li>
                    <li className="rule-list">
                      {" "}
                      বাক্য অনুযায়ী স্বাভাবিক আবেগ প্রকাশ পাবে।
                    </li>
                    <li className="rule-list">
                      পড়ার সময় নিজের স্বাভাবিক গতি বজায় রাখুন, ইচ্ছাকৃতভাবে বেশি
                      ধীরে পড়া যাবে না।
                    </li>
                    <li className="rule-list">
                      বাক্যে নেই এমন কোনো শব্দ/কথা বলা যাবে না। ভুলে অন্য কিছু
                      বলে ফেললে আবার রেকর্ড করুন।
                    </li>
                    <li className="rule-list">
                      কথা বলার সময় খেয়াল রাখবেন অন্য কারো কথা/শব্দ যেন রেকর্ড না
                      না হয়ে যায়। এ ধরনের কোনো ঘটনা ঘটলে প্রয়োজনে আবার রেকর্ড করুন।
                    </li>
                    <li className="rule-list">
                      রেকর্ডের সময় অন্য কোনো আওয়াজ যেন না আসে। তবে কথার চেয়ে
                      ক্ষীণ কিছু আওয়াজ থাকলে সমস্যা নেই।
                    </li>
                    <li className="rule-list">
                      রেকর্ডের সময় ডিভাইস স্বাভাবিক দূরত্বে রাখুন।
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-areabottom">
                <ul className="footer-item">
                  <li className="developed">
                    <span>
                      Designed & Developed by&nbsp;
                      <a
                        style={{
                          color: "blue",
                        }}
                        href="https://www.revesoft.com/">
                        REVE Systems
                      </a>
                    </span>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li className="copyclass">
                    <a>&copy; 2015-2021 All Rights Reserved</a>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li>
                    <a>
                      <img
                        className="img-fluid"
                        src={bangla_logo}
                        alt="bangla"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={bccl} alt="BCC" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={ict} alt="ict" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
