import { useParams } from "react-router-dom";
import Footer from "./assets/js/Footer";
import Header from "./Header";
import ConversationMultiComponent from "./Conversation.multi.component";
import "./assets/css/indexBolunShunun.css";

export default function IndexKothopokothonMany() {
    let { id }: any = useParams();

    return (
        <div className='readbg'>
            <Header />
            {/* <KothopokothonManyBolun id={id}/> */}
                < ConversationMultiComponent />
            <Footer />
       </div>
    )
}