import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";

export default function SpeakerComponent({audioData}:any){

    const [bkash, setBkash]:any = useState(false);
    const [rocket,setRocket]:any = useState(false);
    const [nogod,setNogod]:any = useState(false);
    const [bank,setBank]:any = useState(false);
    const [gift,setGift]:any = useState(false);
    const hour = Math.floor(audioData/3600);
    const rem_time = audioData % 3600;
    const min = Math.floor(rem_time / 60);
    const sec = Math.ceil(rem_time % 60);

    useEffect(() =>{
        console.log("Total hour ",hour)
    },[])

    const handleOnChange = (e:any) => {

        // setIsChecked(!isChecked);
        console.log("Event ",e.target.name);

        if(e.target.name == "bkash"){
            setBkash(true);
            setRocket(false);
            setNogod(false);
            setBank(false);
            setGift(false);
        }
        else if(e.target.name == "rocket"){
            setBkash(false);
            setRocket(true);
            setNogod(false);
            setBank(false);
            setGift(false);
        }
        else if(e.target.name == "nogod"){
            setBkash(false);
            setRocket(false);
            setNogod(true);
            setBank(false);
            setGift(false);
        }
        else if(e.target.name == "bank"){
            setBkash(false);
            setRocket(false);
            setNogod(false);
            setBank(true);
            setGift(false);
        }
        else{
            setBkash(false);
            setRocket(false);
            setNogod(false);
            setBank(false);
            setGift(true);
        }
    };

    return(
        <div>
            <h1><strong>Speaker Dashboard</strong></h1>

            <div>
                <Row>

                    <Col md={4}>
                        <Card style={{
                            background:"#5AACA9"
                        }}>
                            <Card.Body>
                                <strong>রেকর্ডকৃত অডিওর মোট সময়(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                <br />
                                <strong>
                                    {/*{hour} {" "} : {min} : {second}*/}

                                    {
                                        hour
                                    } : {min } : { sec }
                                </strong>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card style={{
                            background:"#EFA315"
                        }}>
                            <Card.Body>
                                <div>
                                    <strong>রিভ কর্তৃক পরীক্ষিত সময়(ঘণ্টা : মিনিট : সেকেন্ড) </strong>
                                    <br />
                                    <strong>
                                        0 : 0 : 0
                                    </strong>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>



            </div>

            <div style={{
                marginTop:"20px"
            }}>
                <Row>

                    <Col md={4}>
                        <Card style={{
                            background:"#61A917"
                        }}>
                            <Card.Body>
                                <strong>মোট গ্রহণযোগ্য সময় (ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                <br />
                                <strong>
                                    0 : 0 : 0
                                </strong>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card style={{
                            background:"#E44609"
                        }}>
                            <Card.Body>
                                <strong>মোট অগ্রহণযোগ্য সময়(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                <br />
                                <strong>
                                    0 : 0 : 0
                                </strong>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </div>

            {/*<div style={{*/}
            {/*    marginTop:"30px"*/}
            {/*}}>*/}
            {/*    <strong>Your Point : 50 </strong>*/}
            {/*</div>*/}

            {/*<div style={{*/}
            {/*    marginTop:"20px"*/}
            {/*}}>*/}
            {/*    <summary><strong><AiFillCaretRight />Select Payment Option</strong></summary>*/}
            {/*    <div className="topping">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="topping"*/}
            {/*            name="bkash"*/}
            {/*            value="Paneer"*/}
            {/*            checked={bkash}*/}
            {/*            onChange={handleOnChange}*/}
            {/*        />*/}
            {/*        Bkash*/}
            {/*    </div>*/}
            {/*    <div className="topping">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="topping"*/}
            {/*            name="rocket"*/}
            {/*            value="Paneer"*/}
            {/*            checked={rocket}*/}
            {/*            onChange={handleOnChange}*/}
            {/*        />*/}
            {/*        Rocket*/}
            {/*    </div>*/}
            {/*    <div className="topping">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="topping"*/}
            {/*            name="nogod"*/}
            {/*            value="Paneer"*/}
            {/*            checked={nogod}*/}
            {/*            onChange={handleOnChange}*/}
            {/*        />*/}
            {/*        Nogod*/}
            {/*    </div>*/}

            {/*    <div className="topping">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="topping"*/}
            {/*            name="bank"*/}
            {/*            value="Paneer"*/}
            {/*            checked={bank}*/}
            {/*            onChange={handleOnChange}*/}
            {/*        />*/}
            {/*        Bank Account*/}
            {/*    </div>*/}

            {/*    <div className="topping">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="topping"*/}
            {/*            name="gift"*/}
            {/*            value="Paneer"*/}
            {/*            checked={gift}*/}
            {/*            onChange={handleOnChange}*/}
            {/*        />*/}
            {/*        Gift Voucher*/}
            {/*    </div>*/}


            {/*    {*/}
            {/*        (bkash || rocket || nogod) ?*/}
            {/*            <form>*/}
            {/*                <label>*/}
            {/*                    Name:*/}
            {/*                    <input type="text" name="name" />*/}
            {/*                </label>*/}
            {/*                <input type="submit" value="Submit" />*/}
            {/*            </form> : null*/}
            {/*    }*/}


            {/*</div>*/}


            {/*<div style={{*/}
            {/*    marginTop:"20px"*/}
            {/*}}>*/}
            {/*    <Row>*/}
            {/*        <Col md={4}>*/}
            {/*            <Card style={{*/}
            {/*                background:"#b0aac0"*/}
            {/*            }}>*/}
            {/*                <Card.Body>*/}
            {/*                    <div>*/}
            {/*                        <strong>Total Paid Bill</strong>*/}
            {/*                        <br />*/}
            {/*                        <strong>*/}
            {/*                            BDT-2000*/}
            {/*                        </strong>*/}
            {/*                    </div>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*        <Col md={4}>*/}
            {/*            <Card style={{*/}
            {/*                background:"#9A6F7C"*/}
            {/*            }}>*/}
            {/*                <Card.Body>*/}
            {/*                    <strong>Total Time Till Last Payment</strong>*/}
            {/*                    <br />*/}
            {/*                    <strong>*/}
            {/*                        5 H*/}
            {/*                    </strong>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}

            {/*    </Row>*/}
            {/*</div>*/}

        </div>
    )
}