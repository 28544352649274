import React, {useEffect,useState} from 'react';
import { useTimer } from 'react-timer-hook';

const BnToEn = (n: any) => n.replace(/[০-৯]/g, (d: any) => "০১২৩৪৫৬৭৮৯".indexOf(d));
const EnToBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);

const timerStyle = {
    fontSize: "20px",
    // backgroundColor: "#EC411B",
    backgroundColor: "#660000",
    borderRadius: "5px",
    padding: "5px 6px",
    color: "white"
}

export default function Timer({ expiryTimestamp, dataPresent, setDataPresent, isConversation, isLecture, startTimer, stop }: any) {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp, onExpire: () => {
            if (isConversation) {
                stop();     
            }
            else {
                // window.removeEventListener("beforeunload", handler);
                if(isLecture){
                    localStorage.setItem('timerExpired', "1");
                }
                
                setDataPresent(false);

                setTimeout(() => {
                    console.warn('onExpire called');
                    window.location.reload();
                }, 100);
                console.warn('onExpire called');
                // window.onbeforeunload = null;
            }
        }
    });

    useEffect(() => {
        console.log("startTimer is", startTimer)
        if(startTimer==false){
            pause();
        }
        else if(startTimer == undefined || startTimer == true){
            resume();
        } 
    }, [startTimer])
    
    return (
        <div style={{ textAlign: 'center' }}>
            {/* <h1>react-timer-hook </h1>
            <p>Timer Demo</p> */}
            <div style={{ fontSize: '20px' }}>
                {/* <span>{days}</span>:<span>{hours}</span>: */}
                <span className='intro' style={timerStyle}>{EnToBn(String(minutes).padStart(2, "0"))}</span>&nbsp;<strong style={{ fontSize: "125%" }}>:</strong>&nbsp;<span className='intro' style={timerStyle}>{EnToBn(String(seconds).padStart(2, "0"))}</span><br />&nbsp;<strong style={{ fontSize: "75%" }}>এর মধ্যে জমা দিন</strong>
            </div>
            {/* <p>{isRunning ? 'Running' : 'Not running'}</p> */}
            {/* <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button>
      <button onClick={resume}>Resume</button>
      <button onClick={() => {
        // Restarts to 5 minutes timer
        const time = new Date();
        time.setSeconds(time.getSeconds() + 300);
        restart(time)
      }}>Restart</button> */}
        </div>
    );
}