import { Store } from "react-notifications-component";
import React, { useState } from "react";
import customContent from "./PageChangeWarning";
export function ToastSuccessMsg(message: any, duration:any=5000) {
  Store.addNotification({
    title: "সফল!",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
    },
  });
}

export function ToastFailedMsg(message: any, duration:any=5000) {
  Store.addNotification({
    title: "ব্যর্থ!",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
    },
  });
}

export function ToastWarningMsg(message: any, duration:any=5000) {
  Store.addNotification({
    title: "সতর্কতা!",
    message: message,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
    },
  });
}

export function TestWarning(message: any,{stayOnPage,setStayOnPage}:any) {
  Store.addNotification({
    // title: "War!",
    message: message,
    type: "warning",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],

    content: customContent(message,{stayOnPage,setStayOnPage})
  });
}


