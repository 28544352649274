import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import { ReactNotifications } from 'react-notifications-component'
import { isLoggedIn } from "./common/http";
import Index from "./pages/crowd-source/Index.page";
import SignUpPage from "./pages/crowd-source/SignUp.page";
import LogInPage from "./pages/crowd-source/LogIn.page";
import AboutPage from "./pages/crowd-source/About.page";
import CrowdDashBoard from "./pages/crowd-source/Dashboard.page";
import SpeakerDashBoardComponent from "./pages/crowd-source/crowd-dashboard/SpeakerDashBoardPage";
import ReadingRulesPage from "./pages/crowd-source/ReadingRules.page";
import ListeningRulesPage from './pages/crowd-source/ListeningRules.page';
import WritingRulesPage from "./pages/crowd-source/WritingRules.page";
import AdminDashboardPage from "./pages/crowd-source/crowd-dashboard/AdminDashboard";
import Userdashboard from "./pages/crowd-source/user-dashboard/Userdashboard";
import IndexBolun from "./pages/crowd-source/IndexBolun";
import IndexShunun from "./pages/crowd-source/IndexShunun2";
import ReportDashboard from './pages/crowd-source/report-dashboard/ReportDashboard';
// import Conversation from './pages/crowd-source-lecture/pages/Conversation';
// import BodyContent from './pages/crowd-source-lecture/components/BodyContent';
// import LectureBolunComponent2 from './pages/crowd-source/Lectrue.Bolun.Component2';
import IndexLecture from './pages/crowd-source/IndexLecture';
import IndexKothopokothonMany from './pages/crowd-source/IndexKothopokothonMany';
// import Bolun from './pages/crowd-source-lecture/pages/Bolun';
import ConfigPanel from './pages/config/ConfigPanel';
function App() {
  const [loginStatus, setLoginStatus]: any = useState({
    loginStatus: isLoggedIn(),
  });
  // const [loginStatus, setLoginStatus]:any = useState();

  /* let menus = [
         { path: "/", name: "main", component: Main },
         { path: "/home", name: "Home", component: Home },
         { path: "/registration", name: "Home", component: Registration }
     ];*/

  useEffect(() => {
    // let status = isLoggedIn();
    // console.log('isLoggedIn', loginStatus);
    // setLoginStatus(status)
  }, []);



  return (
    <div className="App">
      <ReactNotifications />
      {/*<Application />*/}
      <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
        {/*<Router>*/}
        <Switch>
            <>
              <Route exact path="/" component={CrowdDashBoard} />
              <Route path="/reve-crowd-bn/" component={IndexBolun} />
              <Route path="/sign-up" component={SignUpPage} />
              <Route path={"/log-in"} component={LogInPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/reading-rules" component={ReadingRulesPage} />
              <Route path="/speaking-rules" component={WritingRulesPage} />
              <Route path="/listening-rules" component={ListeningRulesPage}/>
              <Route path="/admin-dashboard" component={AdminDashboardPage} />
              <Route
                exact path="/speaker-dashboard"
                component={SpeakerDashBoardComponent}
              />

              <Route path="/user-dashboard" component={Userdashboard} />
              <Route path="/reve-crowd-bn-bolun" component={IndexBolun}/>
              <Route path="/reve-crowd-bn-shunun/" component={IndexShunun}/>
              <Route path="/report-dashboard" component={ReportDashboard}/>
              <Route path="/reve-crowd-bn-conversation-multi" component={IndexKothopokothonMany}/>
              <Route path="/reve-crowd-bn-lecture" component={IndexLecture}/>
              {/* <Route path="/config-panel" component={ConfigPanel}/> */}
            </>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
