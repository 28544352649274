import React, { useState } from "react";
import homelogo from "./images/stts/homelogo.png";
import playstop from "./images/stts/playstop.png";
import { getLoggedInUserId, unsetToken } from "../../common/http";
import { useHistory } from "react-router-dom";
import { ToastSuccessMsg } from "../../common/toast";

export default function MenuComponent({}) {
  let [userId, setUserId]: any = useState(getLoggedInUserId);
  const history = useHistory();

  const onLogout = (e: any) => {
    // eslint-disable-next-line no-restricted-globals
    let con = confirm("লগ আউট নিশ্চিত করুন");
    if (con) {
      ToastSuccessMsg("লগ আউট সম্পন্ন হয়েছে");
      setTimeout(() => unSet(), 2500);
    }
  };

  const unSet = () => {
    unsetToken();
    history.push("/");
    window.location.reload();
  };

  return (
    <div className="topmenu">
      <div className="container smdeviceview">
        {/* <div className='row'> */}
        {/* <div className='col-md-12'> */}
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container-fluid">
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className="navbar-collapse alignnav" id="navbarTogglerDemo01">
              <a
                className="navbar-brand"
                href={(process.env.REACT_APP_ROUTER_BASE || "") + "/"}>
                <img src={homelogo} alt="Logo" />
              </a>

              <ul className="navbar-nav me-auto mb-2 mb-lg-0 obodanitem">
                <li className="nav-item">
                  <a
                    className="nav-link menuactive"
                    aria-current="page"
                    href={(process.env.REACT_APP_ROUTER_BASE || "/") }>
                    হোম
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    href={
                      (process.env.REACT_APP_ROUTER_BASE || "") +
                      "/reve-crowd-bn-bolun"
                    }>
                    অংশ নিন
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href={(process.env.REACT_APP_ROUTER_BASE || "") + "/about"}>
                    আমাদের সম্পর্কে
                  </a>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="#">
                    সম্পর্কিত
                  </Link>
                </li> */}
              </ul>
              {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    <img src={playstop} alt="" />
                  </a>
                </li>
              </ul> */}
              <ul className="navbar-nav me-0 mb-2 mb-lg-0 hideinpage">
                {userId === undefined ? (
                  <li className="nav-item me-3">
                    <a
                      className="nav-link active probeshkorun"
                      aria-current="page"
                      href={
                        (process.env.REACT_APP_ROUTER_BASE || "") + "/log-in"
                      }>
                      প্রবেশ করুন
                    </a>
                  </li>
                ) : (
                  <li className="nav-item me-3">
                    <a
                      className="nav-link active probeshkorun"
                      aria-current="page"
                      href={
                        (process.env.REACT_APP_ROUTER_BASE || "") +
                        "/user-dashboard"
                      }>
                      ড্যাশবোর্ড
                    </a>
                  </li>
                )}

                {userId === undefined ? (
                  <li className="nav-item">
                    <a
                      className="nav-link active nibondonkorun"
                      href={
                        (process.env.REACT_APP_ROUTER_BASE || "") + "/sign-up"
                      }>
                      নিবন্ধন করুন
                    </a>
                  </li>
                ) : (
                  <button
                    style={{
                      marginLeft: "5px",
                      // border: "none",
                      // background: "#ec411b",
                      backgroundColor: "transparent",
                      border: "1px solid #ff5252",
                      borderRadius: "5px",
                      width: "100px",
                      fontSize: "20px",
                      color: "#ec411b",
                      // color: "#fff",
                    }}
                    className="nav-item"
                    onClick={onLogout}>
                    লগ আউট
                  </button>
                )}
              </ul>
            </div>
          </div>
        </nav>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
