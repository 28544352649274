import React, {ReactElement} from "react";
import './LongRunningLoading.scss'

export default function LongRunningLoading({isLoading}: any): ReactElement {
    return (
        <>
            {isLoading &&
                <div className="long-running-loader"/>
            }

        </>
    )
}
