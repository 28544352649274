import React from 'react'
import "../css/footer.css"
import bangla from "../../images/stts/bangla.png"
import bcc from "../../images/stts/bcc.png"
import ict from "../../images/stts/ict.png"
import reve from "../../images/stts/reve.png"

const Footer = () => {
  return (
    <div className='footerarea'>
        <div className="footerleft">
            <div className="footertext">
                <p>সহযোগিতায়:</p>
                 <ul className="brandlogo">
                    <li><a href="#"> <img src={bangla} alt="bangla" /> </a></li>
                    <li><a href="#"> <img src={bcc} alt="bcc" /> </a></li>
                    <li><a href="#"> <img src={ict} alt="ict" /> </a></li>
                 </ul>
            </div>
        </div>
        <div className="footermiddle">
          <p>© 2015-2021 All Rights Reserved by REVE Systems</p>
        </div>
        <div className="footerright">
          <a href="#"> <img src={reve} alt="reve" /> </a>
        </div>
       
    </div>
  )
}

export default Footer