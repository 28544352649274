import React from "react";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl_logo from "./images/bccl_ogo.png";
import ict_logo from "./images/ict_logo.png";
import "./home.scss";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import mulpata from "./images/stts/mulpata.png";
import MenuComponent from "./Menu.Component";

export default function WritingRulesPage() {
  return (
    <div>
      {/* <div className="detailstext"> */}
      <div className="aboutbody">
        <MenuComponent />
        <div className="container smdeviceview">
          <div className="row">
            <div className="col-md-12">
              <div className="jachybradcamp">
                <a
                  href={
                    (process.env.REACT_APP_ROUTER_BASE || "") + "/reve-crowd-bn"
                  }>
                  <img src={mulpata} alt="" />
                </a>
              </div>

              <div className="readingrule">
                <h3>যাচাইয়ের নিয়মাবলী </h3>
                <div className="readblock">
                  <ul>
                    <li className="rule-list">
                      বাক্যের প্রতিটি শব্দ সঠিকভাবে (প্রমিত রূপে) পড়া হয়েছে কিনা
                      যাচাই করুন।
                    </li>
                    <li className="rule-list">
                      শব্দগুলো ধারাবাহিকভাবে পড়া হয়েছে কিনা যাচাই করুন।
                    </li>
                    <li className="rule-list">
                      স্ক্রিপ্টে নেই এমন কোনো শব্দ/কথা চলে এসেছে কিনা যাচাই
                      করুন।
                    </li>
                    <li className="rule-list">
                      একই রেকর্ডিংয়ে অন্য আরেকজনের কথা/শব্দ চলে এসেছে কিনা যাচাই
                      করুন।
                    </li>
                    <li className="rule-list">
                      {" "}
                      রেকর্ডিংয়ে কথা/উচ্চারণের চেয়ে জোরে কোনো আওয়াজ চলে এসেছে
                      কিনা যাচাই করুন।
                    </li>
                    <li className="rule-list">
                      উপরে উল্লিখিত কোনো বিষয়ে বক্তা (স্পিকার) কোনো ভুল করে
                      ফেললে বাক্যটির জন্য ‘না’ অপশেনে ক্লিক করুন। শুধু উল্লিখিত
                      শর্ত পালন করা বাক্যগুলোর জন্যই ‘হ্যাঁ’ অপশনে ক্লিক করবেন।
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-areabottom">
                <ul className="footer-item">
                  <li className="developed">
                    <span>
                      {" "}
                      Designed & Developed by{" "}
                      <a
                        style={{
                          color: "blue",
                        }}
                        href="https://www.revesoft.com/">
                        REVE Systems
                      </a>
                    </span>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li className="copyclass">
                    <a>&copy; 2015-2021 All Rights Reserved</a>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li>
                    <a>
                      <img
                        className="img-fluid"
                        src={bangla_logo}
                        alt="bangla"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={bccl} alt="BCC" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={ict} alt="ict" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
