import React, { useEffect, useState } from "react";
import { Button, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import './SpeakingSectionForAdmin.css';
import { getAdminTableData, getAdminTableDataPaginatedWithSearch, getTotalDurationAndPage, getAdminTableSortedAsc, getAdminTableSortedDesc } from "../crowd_source.service";
import FetchTableDataComponent from "./FetchTableDataComponent";
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import Pagination from './Pagination';
export default function SpeakingSectionForAdminComponent() {
    const [tableData, setTableData]: any = useState([]);
    const [checksetData, setCheckData]: any = useState(false);
    const [totalDuration, setTotalDuration] = useState(0);
    const hour = Math.floor(totalDuration / 3600);
    const rem_time = totalDuration % 3600;
    const min = Math.floor(rem_time / 60);
    const sec = Math.ceil(rem_time % 60);
    const pageNumberLimit = 5;
    //const [passengersData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    //for pagination purposes
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPageLimit, setMaxPageLimit] = useState(5);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    //for search purposes
    const [search, setNewSearch] = useState("");

    //for sorting purposes
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("");

    /*useEffect(() => {
        (async () => {
            //setTableData();
            await fetchAdminTable();
            setLoading(false);

        })();
    }, [currentPage, search]);



    /*const fetchAdminTable = async () => {
        //setTableData(null);
        let res = await getAdminTableDataPaginatedWithSearch("speak", currentPage, 10, search);//page_size=10
        console.log("status ", res.status);
        if (res.status == 200) {

            let { data } = await res.json();
            // setTableData(data);
            console.log("Total File ", data);


            console.log("Data length ", data.length)
            //console.log(total)


            let total = 0;
            data.forEach((obj: any) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (key === "duration") {
                        console.log(`${key} ${value}`);

                        total = total + parseFloat(`${value}`)
                    }
                });
                console.log('-------------------');
            });

            setTableData([]);
            console.log(total);
            //setTotalDuration(total);
            data.forEach((element: any) => {
                setTableData((prevState: any) => [...prevState, element])
            }
            );


        }
        //console.log(currentPage)
        setCheckData(true);

    }*/


    //fetch total duration and total number of actors(required to count page)
    useEffect(() => {
        (async () => {
            //setTableData();
            await fetchTotalDurationAndPage();

        })();
    }, []);



    const fetchTotalDurationAndPage = async () => {
        //setTableData(null);
        let res = await getTotalDurationAndPage();
        console.log("status ", res.status);
        if (res.status == 200) {

            let { data } = await res.json();
            // setTableData(data);
            console.log("Total File ", data);
            setTotalPages(Math.ceil(data.actors / 10));
            setTotalDuration(data.duration);
        }
    }

    const showData = () => {
        console.log("Table data", tableData);
    }


    const onPageChange = (pageNumber: any) => {

        setCurrentPage(pageNumber);
    }
    const onPrevClick = () => {

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
        setCurrentPage(prev => prev - 1);
    }

    const onNextClick = () => {

        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
        setCurrentPage(prev => prev + 1);
    }

    const paginationAttributes = {
        currentPage,
        maxPageLimit,
        minPageLimit,
        totalPages
    };



    //for searchbar purposes

    const handleSearchChange = (event: any) => {
        setNewSearch(event.target.value);
        setCurrentPage(1);
    };

    //for sorting purposes
    useEffect(() => {
        (async () => {
            //setTableData();
            await fetchAdminTableSorted();
            setLoading(false);

        })();
    }, [currentPage, order, search]);

    const fetchAdminTableSorted = async () => {
        //setTableData(null);
        if (order === "asc") {
            let res = await getAdminTableSortedAsc("speak", currentPage, 10, search);
            console.log("status ", res.status);
            if (res.status == 200) {

                let { data } = await res.json();
                // setTableData(data);
                console.log("Total File ", data);
                setTableData(data);
            }
        }
        else if (order === "desc") {
            let res = await getAdminTableSortedDesc("speak", currentPage, 10, search);
            console.log("status ", res.status);
            if (res.status == 200) {

                let { data } = await res.json();
                // setTableData(data);
                console.log("Total File ", data);
                setTableData(data);
            }
        }
        else if (order === "") {
            //setTableData(null);
            let res = await getAdminTableDataPaginatedWithSearch("speak", currentPage, 10, search);//page_size=10
            console.log("status ", res.status);
            if (res.status == 200) {

                let { data } = await res.json();
                // setTableData(data);
                console.log("Total File ", data);


                console.log("Data length ", data.length)
                //console.log(total)


                let total = 0;
                data.forEach((obj: any) => {
                    Object.entries(obj).forEach(([key, value]) => {
                        if (key === "duration") {
                            console.log(`${key} ${value}`);

                            total = total + parseFloat(`${value}`)
                        }
                    });
                    console.log('-------------------');
                });

                setTableData([]);
                console.log(total);
                //setTotalDuration(total);
                data.forEach((element: any) => {
                    setTableData((prevState: any) => [...prevState, element])
                }
                );
            }
            setCheckData(true);
        }
    }



    const [header, setHeader] = React.useState([
        "username", "firstname", "duration"
    ]);

    const handleSortingChange = (accessor: any) => {

        if (accessor === "duration") {
            const sortOrder =
                accessor === sortField && order === "asc" ? "desc" : "asc";
            setSortField(accessor);
            setOrder(sortOrder);
            handleSorting(accessor, sortOrder);
        }
    };


    const handleSorting = (sortField: any, sortOrder: any) => {
        console.log(sortField, sortOrder)
        if (sortField === "duration") {
            console.log("here");
        }
    };




    return (
        checksetData ?
            <div>
                <Container>
                    <div >speaking section</div>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <strong>রেকর্ডকৃত অডিওর মোট সময়</strong>
                                    <br />
                                    <div className="maintext">
                                        {hour} : {min} : {sec}
                                        {/*0: 0: 0*/}
                                    </div>
                                    <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col >
                            <Card style={{ background: "#EFA315" }}>
                                <Card.Body>
                                    <strong>রিভ কর্তৃক পরীক্ষিত সময়</strong>
                                    <br />
                                    <div className="maintext">
                                        {/*{hour} {" "} : {min} : {second}*/}
                                        0: 0: 0
                                    </div>
                                    <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ background: "#61A917" }}>
                                <Card.Body>
                                    <strong>মোট গ্রহণযোগ্য সময়</strong>
                                    <br />
                                    <div className="maintext">
                                        {/*{hour} {" "} : {min} : {second}*/}
                                        0: 0: 0
                                    </div>
                                    <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ background: "#E44609" }}>
                                <Card.Body>
                                    <strong>মোট অগ্রহণযোগ্য সময়</strong>
                                    <br />
                                    <div className="maintext">
                                        {/*{hour} {" "} : {min} : {second}*/}
                                        0: 0: 0
                                    </div>
                                    <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <input type="text" value={search} onChange={handleSearchChange} />
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr key={"header"}>
                            {
                                header.map((heading) =>


                                    <th onClick={() => handleSortingChange(heading)}>
                                        {heading === "duration" ?
                                            <div className="sortDuration">{heading} {order == "" ? <FaSort /> : order == "asc" ? <FaSortUp /> : <FaSortDown />}</div>
                                            :
                                            <div className="keepCursorDefault">{heading}</div>
                                        }
                                    </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((item: any) =>
                                <tr>
                                    <td>{item.username}</td>
                                    <td>{item.firstname}</td>
                                    <td>{item.duration / 60}</td>

                                </tr>
                            )
                        }
                    </tbody>

                </Table>

                {!loading ? <Pagination {...paginationAttributes}
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    onPageChange={onPageChange} />
                    : <div> Loading... </div>
                }
            </div > : null

    )
}