import {del, get, post, postFile, put, getWithPayload, postToPython, getFromPython, postFiletoPython} from "../../common/http";

export const registrationUser = async (formData: any) => {
    return post("/auth/register", formData)
};


export const getUserByUserEmail = (email:any) => {
    console.log("arg: ", email)
    return get(`/api/v1/getNewSpeakerByEmail?email=${email}`)
}

export const findUserByUserEmail = (payload:any) => {
    console.log("arg: ", payload)
    return postToPython(`/find-by-username`, payload)
}


export const registrationUserPython = async (formData: any) => {
    return postToPython("/auth/register", formData)
};

export const getCrowdScripts = (id:any) => {
    return getFromPython(`/get-scripts`);
};

export const getUserPermissionFlag = (id:any) => {
    return postToPython(`/user-permission-check`,id);
};

export const getScript = (value:any) => {
    return get(`/api/v1/getScriptById?id=${value}`);
};

export const getAudioUrl = () => {
    return get(`/api/v1/getAudios`);
};

export const getAudioAndScripts=(listenerId:any)=>{
    const data = {listener_id: listenerId}
    return postToPython(`/getAudiosWithScript`,data);
};

export const getYesReasons = () =>{
    return get(`/api/v1/getYesReasons`);
};
export const getNoReasons = () =>{
    return get(`/api/v1/getNoReasons`);
};

export const postAudio = (data:any) => {
    return postFiletoPython("/postCheckedAudio",data);
};

export const getTotalAudio = () => {
    return get(`/api/v1/get-speaker-audio`);
};

export const getTotalTime = (speakerId:any) => {
    const data = {id: speakerId}
    return postToPython(`/get-speak-total-duration`,data);
};

export const getTotalListeningTime = (speakerId:any) => {
    const data = {id: speakerId}
    return postToPython(`/get-listen-total-duration`,data);

};

export const getAdminTableData = (type:any) => {
    return get(`/api/v1/get-table/${type}`);
}

export const getAdminTableDataPaginatedWithSearch = (type:any="speak", pageNumber:Number=1, pageSize:Number=10, string:any="") => {
    return get(`/api/v1/getAdminTablePaginatedWithSearch?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&string=${string}`);
}

export const getTotalDurationAndPage = () => {
    // return get(`/api/v1/getTotalDurationAndPage`);
    return postToPython(`/total-time-actor`,null);
}

export const getAdminTableSortedAsc = (type:any="speak", pageNumber:Number=1, pageSize:Number=10, string:any="") => {
    return get(`/api/v1/getAdminTableSortedAsc?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&string=${string}`);
}

export const getAdminTableSortedDesc = (type:any="speak", pageNumber:Number=1, pageSize:Number=10, string:any="") => {
    return get(`/api/v1/getAdminTableSortedDesc?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&string=${string}`);
}

export const getDayWiseDuration = (Id:any,Month:any=7, Year:any=2023) => {
    // return get(`/api/v1/get-day-wise-duration?id=${id}&month=${month}`);
    const data = {id : Id, month : Month, year:Year}

    return postToPython(`/get-day-wise-duration`,data)
}

export const getDayWiseDurationListen = (Id:any,Month:any=7, Year:any=2023) => {
    // return get(`/api/v1/get-day-wise-duration-listen?id=${id}&month=${month}`);
    const data = {id : Id, month : Month, year:Year}

    return postToPython(`/get-day-wise-duration-listen`,data)
}

export const getMonthWiseDuration = () => {
    return postToPython(`/monthwise-duration`,null);
}

export const getMonthWiseDurationNew = () => {
    return get(`/api/v1/get-month-wise-duration-new`);
}

export const getSpeechReport = (arg:any) => {
    console.log("arg: ", arg);
    return post("/api/v1/speech-report", arg);
}

export const getUserReport = (arg:any) => {
    console.log("arg: ", arg);
    return post("/api/v1/user-report", arg);
}

export const getShununAudio = (arg:any) => {
    console.log("arg: ", arg);
    return postToPython("/fetch-audio", arg);
}

export const submitUnusedScript = (script:any) => {
    const data = {data: script}
    return postToPython(`/submit-unused-scripts`,data);
};

export const getConvTopics = () => {
    return postToPython(`/get-topics`,null)
}

export const getLectureSlides = (user_id:any, skipped:boolean) => {
    const data = {userId: user_id, skipped: skipped}
    return postToPython(`/get-lecture-slide`,data);
}

export const getCurrentConfig = () => {
    return postToPython(`/get-current-config`,null);
};

export const updateConfig = (arg :any) => {
    return postToPython(`/update-config`,arg);
};