import { useParams } from "react-router-dom";
import Footer from "./assets/js/Footer";
import Header from "./Header";
import Shunun from "./Sunun.component";
import "./assets/css/indexBolunShunun.css";

export default function IndexShunun2() {
    let { id }: any = useParams();

    return (
        <div className='readbg'>
            <Header />
            <Shunun/>
            <Footer />
            </div>
    )
}