import React, { useEffect, useState } from "react";
import "./all.scss";
import "./ttsstyle2.scss";
import "./style.scss";
import "./home.scss";
import signup from "./images/stts/signup.png";
import probesh from "./images/stts/probeshkorun.png";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import { useHistory } from "react-router-dom";
import { isLoggedIn, postToPython, setToken, unsetToken } from "../../common/http";
import { ToastFailedMsg, ToastSuccessMsg } from "../../common/toast";
import MenuComponent from "./Menu.Component";
import LogInComponent from "./LogInComponent";
import PasswordResetModal from "./PasswordResetModal";
import Header from "./Header";
interface FormDataI {
  username: string;
  password: string;
}

export default function LogInPage() {
  const [formData, setFormData] = useState<FormDataI>({
    username: "",
    password: "",
  });
  const history = useHistory();

  useEffect(() => {
    // called when jwt changed
    //  if (isLoggedIn()) {
    //
    //      console.log("LOg in Page ",isLoggedIn())
    //      history.push('/reve-crowd-bn')
    //  }
    if (isLoggedIn()) {
      history.push("/");
    }
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const enterIntoSystem = () => {
    history.push("/");
    window.location.reload();
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    unsetToken();

    let res = await postToPython("/auth/login", formData);
    console.log(res)

    if (res.status === 200 || res.status === 201) {
      let data = await res.json();
      console.log("msg", data);

      setToken(data.data);
      //console.log(token);
      ToastSuccessMsg("লগইন সম্পন্ন হয়েছে");
      setTimeout(() => enterIntoSystem(), 2000);
    } else {
      ToastFailedMsg("লগইন সম্পন্ন হয়নি");
    }
  };
  const [showLoginModal,setShowLoginModal]:any=useState(false);
  /*const funcloginmodal = () =>{
    setShowLoginModal(!showLoginModal);
    console.log(showLoginModal);
    console.log("link was clicked")
  }**/
  function funcloginmodal(e:any){
    e.preventDefault();
    setShowLoginModal(!showLoginModal);
    console.log(showLoginModal);
    console.log("link was clicked");
  }
  return (
    <div>
      {/* <MenuComponent /> */}
      <Header/>
      
      <div className="loginbg">
      {
        showLoginModal?<PasswordResetModal/>:
        <div className="container smdeviceview">
          <div className="row">
            <div className=" col-12 col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-4 col-xxl-6 p-0">
              <div className="breadcrumbtop">
                <p>
                  {/* <img src={signup} alt="" /> */}
                </p>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-7 col-xl-8 col-xxl-6">
              <div className="logindetails">
                <div className="loginform shadow-dark2">
                  <div className="innerlogin">
                    <h4>প্রবেশ করুন</h4>
                    <p className="mb-0">নিবন্ধন চেক করতে লগইন করুন</p>

                    <form onSubmit={onFormSubmit}>
                      <div className="row mtopbottomlogin">
                        <div className="col ps-0 p-0">
                          <input
                            type="text"
                            className="form-control samesize"
                            placeholder="ইউজারনেম"
                            aria-label="First name"
                            name="username"
                            onChange={onInputChange}
                          />
                        </div>
                      </div>

                      <div className="row mtopbottomlogin">
                        <div className="col ps-0 p-0">
                          <input
                            type="password"
                            className="form-control samesize"
                            placeholder="পাসওয়ার্ড"
                            aria-label="First name"
                            name="password"
                            onChange={onInputChange}
                          />
                        </div>
                      </div>
                      <div className="row mtopbottomlogin">
                        <div className="col ps-0 p-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck"
                            />
                            <label className="form-check-label" htmlFor="gridCheck">
                              আমাকে মনে রাখুন
                            </label>
                          </div>
                        </div>
                        <div className="col passreset">
                          <a href="#" className="passreset" onClick={funcloginmodal}>
                            পাসওয়ার্ড রিসেট করুন
                          </a>
                        </div>
                      </div>
                      

                      <div className="row mtopbottomlogin">
                        <div className="col p-0">
                          <div className="loginbutton">
                            <button
                              className="mjomadinbutton"
                              type="submit"
                              style={{
                                border: "none",
                                // background: "none",
                              }}>
                              {/* <img src={probesh} alt=""/> */}
                              প্রবেশ করুন
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mtopbottomlogin">
                        <div className="col p-0">
                          <div className="loginbutton">
                            <p>
                              কোনো অ্যাকাউন্ট নেই?
                              <a
                                href={
                                  (process.env.REACT_APP_ROUTER_BASE || "") +
                                  "/sign-up"
                                }>
                                নিবন্ধন করুন
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-areabottom">
                <ul className="footer-item">
                  <li className="developed">
                    <span>
                      {" "}
                      Designed & Developed by{" "}
                      <a
                        style={{
                          color: "blue",
                        }}
                        href="https://www.revesoft.com/">
                        REVE Systems
                      </a>
                    </span>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li className="copyclass">
                    <a>&copy; 2015-2021 All Rights Reserved</a>
                  </li>
                </ul>
                <ul className="footer-item">
                  <li>
                    <a>
                      <img
                        className="img-fluid"
                        src={bangla_logo}
                        alt="bangla"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={bccl} alt="BCC" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img className="img-fluid" src={ict} alt="ict" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
