import React, { useState, useEffect, PureComponent } from "react";
import "./userdashboard.css";
// import MenuComponent from "./Menu.Component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import MenuComponent from "../Menu.Component";
import bangla_logo from "../images/Bangla_Logo.png";
import bccl_logo from "../images/bccl_ogo.png";
import ict_logo from "../images/ict_logo.png";
import footertop from "../images/footertop.png";
import boluntop from "../images/stts/boluntop.png";
import sununtop from "../images/stts/sununtop.png";
import topcalender from "../images/stts/topcalender.png";
import base from "../images/stts/base.png";
import prize from "../images/stts/prize.png";
import audio from "../images/stts/audio.png";
import leftview from "../images/stts/leftview.png";
import rightview from "../images/stts/rightview.png";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiFillFacebook } from "react-icons/ai";
import {
  getTotalAudio,
  getTotalTime,
  getTotalListeningTime,
  getDayWiseDuration,
  getDayWiseDurationListen,
} from "../crowd_source.service";
import { getLoggedInUserId, isLoggedIn } from "../../../common/http";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { months } from "moment";
import LongRunningLoading from "../../../common/LongRunningLoading";
import { Button } from "react-bootstrap";
import { getToken } from "../../../common/http";
import OngshoninRecordingAreaModal from "../../../common/OngshoninRecordingAreaModal";
import Modal from 'react-bootstrap/Modal';
import { setLocalRecordingArea } from "../../../common/http";
import { IconContext } from "react-icons";

export default function Userdashboard() {
  const current = new Date();
  const currentMonth = `${current.getMonth() + 1}`;
  const currentMonthListen = `${current.getMonth() + 1}`;
  const currentYear = `${current.getFullYear()}`;
  const [audioCount, setAudioCount]: any = useState(0);
  const [bolunTime, setBolunTime]: any = useState(0.000);
  const [shununTime, setShununTime]: any = useState(0.000);
  const speakerId = getLoggedInUserId();
  let [bolunHour, setBolunHour]: any = useState(0.000);
  let [bolunMinute, setBolunMinute]: any = useState(0.000);
  let [bolunSecond, setBolunSecond]: any = useState(0.000);
  let [shununHour, setShununHour]: any = useState(0.000);
  let [shununMinute, setShununMinute]: any = useState(0.000);
  let [shununSecond, setShununSecond]: any = useState(0.000);
  let [speakGraphdata, setSpeakGraphData]: any = useState(null);
  let [listenGraphdata, setListenGraphData]: any = useState(null);
  let [monthLength, setMonthLength]: any = useState(null);
  const [presentMonth, setPresentMonth]: any = useState(parseInt(currentMonth));
  const [presentMonthListen, setPresentMonthListen]: any = useState(
    parseInt(currentMonthListen)
  );
  const [presentYear, setPresentYear]:any = useState(currentYear);
  const [presentYearListen, setPresentYearListen]:any = useState(currentYear);
  let [isLoading, setIsLoading]: any = useState(true);
  let [isLoadingListen, setIsLoadingListen]: any = useState(true);

  const BnToEn = (n: any) => n.replace(/[০-৯]/g, (d: any) => "০১২৩৪৫৬৭৮৯".indexOf(d));
  const EnToBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);

  let token = getToken();
  let newString = "{}";
  let detailsObj;
  //console.log(token.details)
  if (token.details == "") {
    detailsObj = JSON.parse(newString);
  } else if (token.details != null) {
    newString = token.details.replaceAll(/"\"/g, "");
    //newString=newString.slice(0,-2)+"}";
    //detailsObj=JSON.parse(newString);
    detailsObj = JSON.parse(token.details);
  }
  //console.log("gettoken is", detailsObj);

  useEffect(() => {
    (async () => {
      await getTotalAudioTime();
    })();
  }, []);


  const [acceptedTimeBolunHour, setAcceptedTimeBolunHour]: any = useState(0);
  const [acceptedTimeBolunMinute, setAcceptedTimeBolunMinute]: any = useState(0);
  const [acceptedTimeBolunSecond, setAcceptedTimeBolunSecond]: any = useState(0);
  const [rejectedTimeBolunHour, setRejectedTimeBolunHour]: any = useState(0);
  const [rejectedTimeBolunMinute, setRejectedTimeBolunMinute]: any = useState(0);
  const [rejectedTimeBolunSecond, setRejectedTimeBolunSecond]: any = useState(0);
  const [reveTimeBolunHour, setReveTimeBolunHour]: any = useState(0);
  const [reveTimeBolunMinute, setReveTimeBolunMinute]: any = useState(0);
  const [reveTimeBolunSecond, setReveTimeBolunSecond]: any = useState(0);

  const getTotalAudioTime = async () => {
    setPresentMonth(parseInt(presentMonth));
    setPresentMonthListen(parseInt(presentMonthListen));

    //setIsLoading(true);
    console.log(speakerId);
    let res = await getTotalTime(speakerId);
    if (res.status == 200) {
      let { data, data_accepted, data_rejected, data_reve } = await res.json();
      setBolunTime(data);
      console.log("Total Bolun Time: ", data);

      let hours = Math.floor(parseFloat(data) / 3600);
      setBolunHour(EnToBn(String(hours)));

      let minutes = Math.floor((parseFloat(data) % 3600) / 60);
      setBolunMinute(EnToBn(String(minutes)));

      let seconds = Math.round((parseFloat(data) % 3600) % 60);
      setBolunSecond(EnToBn(String(seconds)));

      console.log(`রেকর্ডকৃত অডিওর মোট সময়: ${hours} ঘণ্টা ${minutes} মিনিট ${seconds} সেকেণ্ড`);

      setAcceptedTimeBolunHour(EnToBn(String(Math.floor(parseFloat(data_accepted) / 3600))));
      setAcceptedTimeBolunMinute(EnToBn(String(Math.floor((parseFloat(data_accepted) % 3600) / 60))));
      setAcceptedTimeBolunSecond(EnToBn(String(Math.round((parseFloat(data_accepted) % 3600) % 60))));

      setRejectedTimeBolunHour(EnToBn(String(Math.floor(parseFloat(data_rejected) / 3600))));
      setRejectedTimeBolunMinute(EnToBn(String(Math.floor((parseFloat(data_rejected) % 3600) / 60))));
      setRejectedTimeBolunSecond(EnToBn(String(Math.round((parseFloat(data_rejected) % 3600) % 60))));

      setReveTimeBolunHour(EnToBn(String(Math.floor(parseFloat(data_reve) / 3600))));
      setReveTimeBolunMinute(EnToBn(String(Math.floor((parseFloat(data_reve) % 3600) / 60))));
      setReveTimeBolunSecond(EnToBn(String(Math.round((parseFloat(data_reve) % 3600) % 60))));
    }
    let res2 = await getTotalListeningTime(speakerId);
    if (res2.status == 200) {
      let { data } = await res2.json();
      setShununTime(data);
      console.log("Total Shunun Time: ", data);

      let hours = Math.floor(parseFloat(data) / 3600);
      setShununHour(EnToBn(String(hours)));

      let minutes = Math.floor((parseFloat(data) % 3600) / 60);
      setShununMinute(EnToBn(String(minutes)));

      let seconds = Math.round((parseFloat(data) % 3600) % 60);
      setShununSecond(EnToBn(String(seconds)));

      console.log(`যাচাইকৃত অডিওর মোট সময়: ${hours} ঘণ্টা ${minutes} মিনিট ${seconds} সেকেণ্ড`);
    }
  };

  useEffect(() => {
    console.log("ïsloadinng:", isLoading);
    (async () => {
      // await getSpeakerScript();
      // await getTotalAudioFile();
      setIsLoading(true);
      await fetchDayWiseDuration();
    })();
  }, [presentMonth]);

  useEffect(() => {
    console.log("ïsloadinng:", isLoadingListen);
    (async () => {
      // await getSpeakerScript();
      // await getTotalAudioFile();
      setIsLoadingListen(true);
      await fetchDayWiseDurationListen();
    })();
  }, [presentMonthListen]);

  const fetchDayWiseDuration = async () => {
    var durationList = [];
    let res = await getDayWiseDuration(speakerId, presentMonth, presentYear);
    console.log("presentnmonth is", presentMonth);
    if (presentMonth === 1) {
      for (var i = 1; i < 32; i++) {
        durationList[i] = 0;
      }
    }
    if (res.status == 200) {
      setIsLoading(false);
      let { data } = await res.json();
      let graphData = data;
      const month31 = [1, 3, 5, 7, 8, 10, 12];
      const month30 = [4, 6, 9, 11];
      console.log(month31.includes(presentMonth));
      if (month31.includes(presentMonth)) {
        monthLength = 31;
      } else if (month30.includes(presentMonth)) {
        monthLength = 30;
      } else if (presentMonth === 2) {
        monthLength = 28;
      }

      for (var i = 1; i <= monthLength; i++) {
        durationList[i] = 0;
      }
      for (var i = 0; i < graphData.length; i++) {
        durationList[graphData[i].day] = graphData[i].duration;
      }
      console.log(durationList);
      console.log(graphData);
      let reqData = [];
      for (var i = 1; i <= monthLength; i++) {
        var newEntry = { day: i, duration: durationList[i] };
        reqData.push(newEntry);
      }
      setSpeakGraphData(reqData);
    }
  };

  const fetchDayWiseDurationListen = async () => {
    var durationList = [];
    let res = await getDayWiseDurationListen(speakerId, presentMonthListen, presentYearListen);
    console.log("presentnmonthListen is", presentMonthListen);
    if (presentMonthListen === 1) {
      for (var i = 1; i < 32; i++) {
        durationList[i] = 0;
      }
    }
    if (res.status == 200) {
      setIsLoadingListen(false);
      let { data } = await res.json();
      let graphData = data;
      const month31 = [1, 3, 5, 7, 8, 10, 12];
      const month30 = [4, 6, 9, 11];
      console.log(month31.includes(presentMonthListen));
      if (month31.includes(presentMonthListen)) {
        monthLength = 31;
      } else if (month30.includes(presentMonthListen)) {
        monthLength = 30;
      } else if (presentMonthListen === 2) {
        monthLength = 28;
      }

      for (var i = 1; i <= monthLength; i++) {
        durationList[i] = 0;
      }
      for (var i = 0; i < graphData.length; i++) {
        durationList[graphData[i].day] = graphData[i].duration;
      }
      console.log(durationList);
      console.log(graphData);
      let reqData = [];
      for (var i = 1; i <= monthLength; i++) {
        var newEntry = { day: i, duration: durationList[i] };
        reqData.push(newEntry);
      }
      setListenGraphData(reqData);
      console.log(reqData);
    }
  };

  /*const handleMonthChange = (accessor: any) => {
        const month31 = [1, 3, 5, 7, 8, 10, 12]
        const month30 = [4, 6, 9, 11]
        console.log("handling month", presentMonth);
        if (month31.includes(accessor)) {
            setMonthLength(31);
            console.log("here");
        }
        else if (month30.includes(accessor)) {
            setMonthLength(30);
        }
        else {
            setMonthLength(28);
        }
        console.log(monthLength);
    };*/
  const monthValue = 1;
  const CustomTooltip = ({ active, payload, label }: any) => {
    //console.log("payload ",`${payload[0].value}`);
    if (active && payload && payload.length) {
      let seconds = EnToBn(String(payload[0].value.toFixed(2)));
      let date = EnToBn(String(payload[0].payload["day"]));

      return (
        <div className="custom-tooltip">
          <p className="label"></p>
          <span className="intro">তারিখ: {date}</span><br /><span className="intro">সময়: {seconds} সেকেন্ড</span>
        </div>
      );
    }

    return null;
  };

  const [showModal, setShowModal]: any = useState(false);
  const showRecordingAreaModal = () => {
    // setShowModal(true);
    console.log("here");
    setRecordAreaShow(true);
  }
  const [recordAreaShow, setRecordAreaShow]: any = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); };
  const recordingAreas = [
    "রুমের মধ্যে",
    "রুমের বাইরে",
    "মার্কেটে",
    "মাঠে",
    "রাস্তায়",
    "উন্মুক্ত স্থানে",
    "অন্যান্য জনবহুল স্থানে"
  ];

  const onRecordingAreaChange = (val: any) => {
    // alert(val)
    handleClose();
    console.log("recording area: ", val);
    setLocalRecordingArea(val);
  };

  const prevMonth = (type: any) => {
    if (type == "speak") {
      let newPresentMonth = presentMonth - 1;
      if (presentMonth == 1) {
        newPresentMonth = 12
        setPresentYear(Number(presentYear)-1);
      }
      setPresentMonth(newPresentMonth);
    }
    else {
      let newPresentMonth = presentMonthListen - 1;
      if (presentMonthListen == 1) {
        newPresentMonth = 12
        setPresentYearListen(Number(presentYearListen)-1);
      }
      setPresentMonthListen(newPresentMonth);
    }
  };

  const nextMonth = (type: any) => {
    if (type == "speak") {
      let newPresentMonth = presentMonth + 1;
      if (presentMonth == 12) {
        newPresentMonth = 1
        setPresentYear(Number(presentYear+1));
      }
      setPresentMonth(newPresentMonth);
    }
    else {
      let newPresentMonth = presentMonthListen + 1;
      if (presentMonthListen == 12) {
        newPresentMonth = 1
        setPresentYearListen(Number(presentYearListen+1));
      }
      setPresentMonthListen(newPresentMonth);
    }
  };

  let [monthSpeak, setMonthSpeak]: any = useState();
  let [monthListen, setMonthListen]: any = useState();
  let [yearSpeak, setYearSpeak]: any = useState();
  let [yearListen, setYearListen]: any = useState();
  let monthsInBangla = ["জানুয়ারি", "ফেব্রুয়ারী", "মার্চ", "এপ্রিল", "মে", "জুন", "জুলাই", "আগষ্ট", "সেপ্টেম্বর", "অক্টোবর", "নভেম্বর", "ডিসেম্বর"]

  useEffect(() => {
    setMonthSpeak(monthsInBangla[presentMonth - 1]);
    // let temp = EnToBn(presentYear);
    // if(temp)
    console.log(typeof(presentYear))
    // setYearSpeak(EnToBn(presentYear));
    // setYearSpeak((presentYear));
    setMonthListen(monthsInBangla[presentMonthListen - 1]);
    // let temp2 = EnToBn(presentYearListen);
    // if(temp2)setYearListen(temp2);
  }, [presentMonth, presentMonthListen]);


  return (
    <div
      className="userdashboard"
      style={{
        marginTop: "-50px",
      }}>
      <MenuComponent />
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
        centered><Modal.Body className="bolunshununmodal">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle recordingmodal"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              রেকর্ডিং এলাকা
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {
                recordingAreas.map(
                  area => {
                    return <li key={area}>
                      <a href={
                        (process.env.REACT_APP_ROUTER_BASE || "") +
                        "/reve-crowd-bn-lecture"
                        // "/reve-crowd-bn-conversation-multi"
                      } className="dropdown-item" onClick={() => onRecordingAreaChange(area)}> {area}</a>
                    </li>;
                  }
                )
              }
            </ul>
          </div>
        </Modal.Body></Modal>
      {/* {showModal? */}
      {/* (<OngshoninRecordingAreaModal recordAreaShow={recordAreaShow} setRecordAreaShow={setRecordAreaShow}/>) */}
      {/* :null} */}
      <div></div>

      <div className="container smdeviceview mt-5">
        <div className="row">
          <div className="col-sm-12 col-md-1 col-lg-2 col-xl-2">
            <div className="leftmenuarea">
              <div className="borderright">
                <div className="borderacitve"></div>
                <nav>
                  <ul>
                    <li>
                      <a href="" className="active">
                        ড্যাশবোর্ড
                      </a>
                    </li>
                    <li>
                      <a href="">অর্জন</a>
                    </li>
                    <li>
                      <a href="">প্রোফাইল</a>
                    </li>
                    <li>
                      <a href="">সেটিংস</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className=" col-sm-12 col-md-8 col-lg-7 col-xl-8" style={{overflow: "auto"}}>
            <div className="contentarea">
              <div className="toptitle">
                <div className="lefttitle">
                  <h4>আপনার যত রেকর্ড</h4>
                </div>
                <div className="righttitle">
                  <p>বলুন</p>
                  <span style={{ cursor: "pointer" }} onClick={() => { setShow(true) }}><img src={boluntop} alt="boluntop" /></span>
                </div>
              </div>
              <div className="grapharea">
                {isLoading ? (
                  <LongRunningLoading isLoading={isLoading} />
                ) : (
                  <div>
                    <div className="graphtitle">
                      <div className="lefttitle">
                        <h4>রেকর্ডকৃত অডিও</h4>
                      </div>
                      <div style={{ border: "0px solid red", height: "fit-content", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div className="mx-0 px-0" style={{ cursor: "pointer", display: "inline-block" }}><a onClick={() => prevMonth("speak")}><IoIosArrowBack /></a></div>
                        <div className="px-3 py-1 rounded mx-0" style={{ display: "inline-block", background: "#ff5767" }}>
                          <p style={{
                            fontFamily: "SolaimanLipi",
                            fontStyle: "normal",
                            fontWeight: "300",
                            fontSize: "12px",
                            margin: "0px",
                            color: "#ffffff"
                          }}>{monthSpeak} {EnToBn(presentYear.toString())}</p>
                        </div>
                        <div className="mx-0 px-0" style={{ cursor: "pointer", display: "inline-block" }}><a onClick={() => nextMonth("speak")}><IoIosArrowForward /></a></div>
                      </div>
                    </div>
                    <div className="maingraph">
                      <ResponsiveContainer minHeight={200} minWidth="100%">
                        <BarChart
                          //className="graphbar"
                          width={700}
                          height={200}
                          data={speakGraphdata}
                          margin={{
                            top: 5,
                            right: 15,
                            left: -15,
                            bottom: 5,
                          }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="day" minTickGap={5} />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend />
                          <Bar dataKey="duration" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}
                <div className="recorddata">
                  <div className="singlerecord recordaudio">
                    <p>রেকর্ডকৃত অডিওর মোট সময়</p>
                    <h6>
                      {bolunHour} ঘণ্টা {bolunMinute} মিনিট {bolunSecond} সেকেন্ড
                    </h6>
                  </div>
                  <div className="singlerecord revetime">
                    <p>রিভ কর্তৃক পরীক্ষিত সময়</p>
                    <h6>{reveTimeBolunHour} ঘন্টা {reveTimeBolunMinute} মিনিট {reveTimeBolunSecond} সেকেন্ড</h6>
                  </div>
                  <div className="singlerecord totaltime">
                    <p>মোট গ্রহণযোগ্য সময়</p>
                    <h6>{acceptedTimeBolunHour} ঘন্টা {acceptedTimeBolunMinute} মিনিট {acceptedTimeBolunSecond} সেকেন্ড</h6>
                  </div>
                  <div className="singlerecord totalreducetime">
                    <p>মোট অগ্রহণযোগ্য সময়</p>
                    <h6>{rejectedTimeBolunHour} ঘন্টা {rejectedTimeBolunMinute} মিনিট {rejectedTimeBolunSecond} সেকেন্ড</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* ====================== Second Graph ========================== */}
            <div className="contentarea">
              <div className="toptitle sununtitle">
                <div className="lefttitle">
                  <h4 style={{ color: "#136EE5" }}>আপনার যত যাচাই</h4>
                </div>
                <div className="righttitle">
                  <p>শুনুন</p>
                  <a href={(process.env.REACT_APP_ROUTER_BASE || "") + "/reve-crowd-bn-shunun"}><img src={sununtop} alt="boluntop" /></a>
                </div>
              </div>
              <div className="grapharea">
                {isLoadingListen ? (
                  <LongRunningLoading isLoading={isLoadingListen} />
                ) : (
                  <div>
                    <div className="graphtitle">
                      <div className="lefttitle">
                        <h4>যাচাই করেছেন</h4>
                      </div>
                      <div style={{ border: "0px solid red", height: "fit-content", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div className="mx-0 px-0" style={{ cursor: "pointer", display: "inline-block" }}><a onClick={() => prevMonth("listen")}><IoIosArrowBack /></a></div>
                        <div className="px-3 py-1 rounded mx-0" style={{ display: "inline-block", background: "#246fdf" }}>
                          <p style={{
                            fontFamily: "SolaimanLipi",
                            fontStyle: "normal",
                            fontWeight: "300",
                            fontSize: "12px",
                            margin: "0px",
                            color: "#ffffff"
                          }}>{monthListen} {EnToBn(presentYearListen.toString())}</p>
                        </div>
                        <div className="mx-0 px-0" style={{ cursor: "pointer", display: "inline-block" }}><a onClick={() => nextMonth("listen")}><IoIosArrowForward /></a></div>
                      </div>
                    </div>
                    <div className="maingraph">
                      <ResponsiveContainer minHeight={200} minWidth="100%">
                        <BarChart
                          //className="graphbar"
                          width={700}
                          height={200}
                          data={listenGraphdata}
                          margin={{
                            top: 5,
                            right: 15,
                            left: -15,
                            bottom: 5,
                          }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="day" minTickGap={5} />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend />
                          <Bar dataKey="duration" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}
                <div className="recorddata">
                  <div className="singlerecord recordaudio srecordaudio ">
                    <p>যাচাইকৃত অডিওর মোট সময়</p>
                    <h6>
                      {shununHour} ঘণ্টা {shununMinute} মিনিট {shununSecond} সেকেন্ড
                    </h6>
                  </div>
                  <div className="singlerecord revetime srevetime">
                    <p>রিভ কর্তৃক যাচাই নিরীক্ষার সময়</p>
                    <h6>০ ঘণ্টা ০ মিনিট</h6>
                  </div>
                  <div className="singlerecord totaltime stotaltime">
                    <p>মোট গ্রহণযোগ্য সময়</p>
                    <h6>০ ঘণ্টা ০ মিনিট</h6>
                  </div>
                  <div className="singlerecord totalreducetime stotalreducetime">
                    <p>মোট অগ্রহণযোগ্য সময়</p>
                    <h6>০ ঘণ্টা ০ মিনিট</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3  col-lg-3 col-xl-2">
            <div className="rightsidearea">
              <div className="singlesidebar">
                <div className="rititle">
                  <img src={base} alt="base" />
                  <p>সম্মানী:</p>
                </div>
                <div className="rcontent">
                  <p>
                    <span>১.</span> প্রতি ৬০ মিনিটের (১ ঘণ্টা) গ্রহণযোগ্য অডিও’র
                    জন্য ২০০ টাকা সম্মানী প্রাপ্য।
                  </p>
                  <p>
                    <span>২.</span> ন্যুনতম ২০০ টাকা প্রাপ্য হলে সম্মানী পাঠানো
                    হবে।
                  </p>
                  <p>
                    <span>৩.</span> সম্মানী প্রদানের লক্ষ্যে প্রতি মাসে দুইবার
                    (১ ও ১৫ তারিখ) মোট অডিও’র হিসাব গণনা করা হবে। অর্থাৎ,
                    একবারের গণনায় এর আগের ২ সপ্তাহের অডিও রেকর্ড ও গ্রহণযোগ্য
                    রেকর্ডিংয়ের তথ্য হিসাব করা হবে।
                  </p>
                  <p>
                    <span>৪.</span>প্রত্যেক হিসাবের পরবর্তী ৩ কার্যদিবসের মধ্যে
                    সম্মানী পৌঁছে যাবে।
                  </p>
                  <p>
                    <span>৫.</span> নিবন্ধনের (রেজিস্ট্রেশন) সময় আপনি যে বিকাশ/নগদ/রকেট নাম্বার দিয়েছেন, সেই নাম্বারে সম্মানী পাঠিয়ে দেওয়া হবে।
                  </p>
                </div>
              </div>
              <div className="singlesidebar">
                <div className="rititle">
                  <img src={audio} alt="base" />
                  <p>অডিও রেকর্ডের শর্তাবলি:</p>
                </div>
                <div className="rcontent" style={{ borderBottom: "none", marginBottom: "20px" }}>
                  <p>
                    <span>১.</span> স্ক্রিনে আসা বাক্যগুলোর প্রতিটি শব্দ
                    প্রমিতরূপে, সঠিকভাবে ও ধারাবাহিকতা রক্ষা করে পড়তে হবে।
                  </p>
                  <p>
                    <span>২.</span> বাক্য অনুযায়ী স্বাভাবিক আবেগ প্রকাশ
                    পাবে।
                  </p>
                  <p>
                    <span>৩.</span> পড়ার সময় স্বাভাবিক গতির চেয়ে বেশি ধীরে
                    পড়া যাবে না।
                  </p>
                  <p>
                    <span>৪.</span>কথোপকথনের ক্ষেত্রেও প্রমিত বাংলায় কথা
                    বলতে হবে। আঞ্চলিক ভাষায় কথা বলা যাবে না।
                  </p>
                  <p>
                    <span>৫.</span>বাক্য শুরুর আগের ও পরের সময় হিসাব করা হবে
                    না।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============================== Footer Content start here ===================== */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-areabottom">
              <ul className="footer-item">
                <li className="developed">
                  <span>
                    Designed & Developed by&nbsp;
                    <a
                      style={{
                        color: "blue",
                      }}
                      href="https://www.revesoft.com/">
                      REVE Systems
                    </a>
                  </span>
                </li>
              </ul>
              <ul className="footer-item">
                <li className="copyclass">
                  <a>mail: news4reve@gmail.com</a>
                  <a href="https://www.facebook.com/Amar-Kontho-107355312220670"><IconContext.Provider
                    value={{ color: '#1877F2', size: '28px' }}
                  ><AiFillFacebook /></IconContext.Provider></a>
                </li>
              </ul>
              <ul className="footer-item">
                <li>
                  <a>
                    <img className="img-fluid" src={bangla_logo} alt="bangla" />
                  </a>
                </li>
                <li>
                  <a>
                    <img className="img-fluid" src={bccl_logo} alt="BCC" />
                  </a>
                </li>
                <li>
                  <a>
                    <img className="img-fluid" src={ict_logo} alt="ict" />
                  </a>
                </li>
              </ul>
              <div className="footerleftimg">
                <img src={footertop} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============================== Footer Content start here ===================== */}
    </div >
  );
}
