import React, {useState} from "react";
import {Tabs,Tab} from "react-bootstrap";
import ReportDashboard from "../report-dashboard/ReportDashboard";
import ListeningSectionForAdminComponent from "./ListeningSectionForAdmin.Component";
import SpeakingSectionForAdminComponent from "./SpeakingSectionForAdmin.Component";

export default function AdminDashboardPage() {

    const [speakerSection,setSpeakerSection]:any = useState(true);



    return(
        <div style={{
            marginTop:"20px",
            marginLeft:"10%",
            marginRight: "10%"
        }}>
            {/*<div className="tab">*/}
            {/*    <button className="tablinks" onClick={()=>(setSpeakerSection(true))}>Speaker</button>*/}
            {/*    <button className="tablinks" onClick={()=>(setSpeakerSection(false))} >Listener</button>*/}

            {/*</div>*/}

            <Tabs
                defaultActiveKey="home"
                transition={false}
                id="noanim-tab-example"
                className="mb-2"
            >
                <Tab eventKey="home" title="Speaker Section">
                    <SpeakingSectionForAdminComponent />
                </Tab>
                <Tab eventKey="profile" title="Listener Section">
                    <ListeningSectionForAdminComponent />
                </Tab>
                <Tab eventKey="report-dashboard" title="Report Dashboard">
                    <ReportDashboard />
                </Tab>
            </Tabs>
        </div>
    )
}