import { useState } from "react";
import { useParams } from "react-router-dom";
import { getLoggedInUserId } from "../../common/http";
import Footer from "./assets/js/Footer";
import Header from "./Header";
import BolunComponent from "./Bolun.component";
import "./assets/css/indexBolunShunun.css";

export default function IndexBolun() {
    let { id }: any = useParams();

    let [speakerId, setSpeakerId]: any = useState(getLoggedInUserId);

    console.log("speaker-id: ", speakerId);
    
    console.log("id: ", id)
    return (
        <div className='readbg'>
            <Header />
            <BolunComponent id={id}/>
            <Footer />
        </div>
    )
}