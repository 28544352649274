import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FetchTableDataComponent from './FetchTableDataComponent';

export default function ListeningSectionForAdminComponent() {
  return (

    <div>
      <Container>
        <div >Listening Section</div>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <strong>যাচাইকৃত অডিওর মোট সময়</strong>
                <br />
                <div className="maintext">
                  {/*{hour} {" "} : {min} : {second}*/}
                  0: 0: 0
                </div>
                <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
              </Card.Body>
            </Card>
          </Col>
          <Col >
            <Card style={{ background: "#EFA315" }}>
              <Card.Body>
                <strong>রিভ যাচাই নিরীক্ষার সময়</strong>
                <br />
                <div className="maintext">
                  {/*{hour} {" "} : {min} : {second}*/}
                  0: 0: 0
                </div>
                <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ background: "#61A917" }}>
              <Card.Body>
                <strong>মোট গ্রহণযোগ্য সময়</strong>
                <br />
                <div className="maintext">
                  {/*{hour} {" "} : {min} : {second}*/}
                  0: 0: 0
                </div>
                <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ background: "#E44609" }}>
              <Card.Body>
                <strong>মোট অগ্রহণযোগ্য সময়</strong>
                <br />
                <div className="maintext">
                  {/*{hour} {" "} : {min} : {second}*/}
                  0: 0: 0
                </div>
                <strong>(ঘণ্টা : মিনিট : সেকেন্ড)</strong>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <FetchTableDataComponent /> */}
    </div >
  )
}