import React, {useEffect, useState} from "react";
import {getTotalAudio, getTotalTime} from "../crowd_source.service";
import LongRunningLoading from "../../../common/LongRunningLoading";
import './SpeakerDashboard.css';
import SpeakerComponent from "./SpeakerComponent";
import ListenerComponent from "./ListenerComponent";
import {getLoggedInUserId, isLoggedIn} from "../../../common/http";

export default function SpeakerDashBoardComponent(){

    const [audioCount,setAudioCount]:any = useState(0);
    const [audioTime,setAudioTime]:any = useState(0);
    const [isLoading,setIsLoading]:any = useState(true);
    const [speakerDashboard,setSpeakerDashboard]:any = useState(true);
    const speakerId = getLoggedInUserId();
    let [hour,setHour]:any = useState(null);
    let [min,setMin]:any = useState(null);
    let [second,setSecond]:any = useState(null);



    useEffect(() => {
        //called when jwt changed
        console.log("User Long in Id ",getLoggedInUserId());

    }, [])



    useEffect(() => {
        (async () => {
            // await getSpeakerScript();
           // await getTotalAudioFile();
            await getTotalAudioTime();
        })();

        //  if(!submitState){
        //      window.onbeforeunload = function(){
        //          return true;
        //      };
        //      return ()=>{
        //          window.onbeforeunload = null;
        //      };
        //  }
    }, []);

    const id = 618;

    const getTotalAudioFile = async () =>{
        let res = await getTotalAudio();

        if(res.status == 200 ){

            let {data} = await res.json();

            setAudioCount(data);
            setIsLoading(false);

            console.log("Total File ",data)
        }
    }

    const getTotalAudioTime = async () =>{

        let res = await getTotalTime(speakerId);
        if(res.status == 200 ){

            let {data} = await res.json();
            setAudioTime(data);
            console.log("Total File ",data)

        }
    }

    const [open,setOpen]:any = useState(false);

    const onToggle = (event:any) => {
        event.preventDefault();
        setOpen(!open);
    };




    return(
        <div style={{
            marginTop:"20px",
            marginLeft:"10%"
        }}>

            {/*<MenuComponent />*/}
            <div className="tab">
                <button className="tablinks" onClick={()=>(setSpeakerDashboard(true))}>Speaker</button>
                <button className="tablinks" onClick={()=>(setSpeakerDashboard(false))} >Listener</button>

            </div>

            {/*<LongRunningLoading isLoading={isLoading} />*/}

            {
                    speakerDashboard?
                    <SpeakerComponent audioData={audioTime}/>
                    :
                    <ListenerComponent />

            }

        </div>
    )
}