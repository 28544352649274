

import {post, get, postFile, del, put, postFiletoPython} from "../../common/http";


export const {dataSource} = {
    dataSource: ['MONO', 'DI', 'MULTI',
        'READ', 'LECTURE', 'SPONTANEOUS','COMMAND', 'MISCELLANEOUS'
    ]
}

export const getSentenceWiseCollectionsData = (payload:any) => {
    return post(`/api/v1/sentence-wise-data-by-collectorId`, payload);
};


export const deleteSentenceWiseCollectionById = (id:any) => {
    return del(`/sentence-wise-collection/delete/${id}`);
};


export const regularUploadCollection = (data:any) => {
    return postFile("/collections", data);
};

// export const uploadCrowdSourceAudio = (data: any) => {
//     return postFile("/crowd-source-collection", data);
// };


// for python backend
export const uploadCrowdSourceAudio = (data: any) => {
    return postFiletoPython("/crowd-source-collection", data);
};

export const uploadUnScriptedCrowdSourceAudio = (data: any) => {
    return postFile("/unscripted-crowd-source-collection", data);
};

export const sentenceWiseUpload = (data:any) => {
    return postFile("/sentence-wise-collection", data);
};


export const updateCollection = (data:any) => {
    return postFile("/update-collections", data);
};
export const saveAnnotationFile = (data:any) => {
    return postFile("/save/annotation", data);
};

export const uploadSingleZipFile = (data:any) => {
    return postFile("/collections/zip", data);
};

export const deleteCollectionById = (id:any) => {
    return del(`/collections/delete/${id}`);
};


export const getCollectionsData = () => {
    return get(`/api/v1/collections`);
};

export const getSpeakerScriptData = () => {
    return get(`/api/v1/speaker-scripts`);
};

export const getCollectionsDataByLabel = (label:number) => {
    return get(`/api/v1/collection-by-label/${label}`);
};

export const getAllCollectionsByCollectorId = (payload:any) => {
    return post(`/api/v1/collection-by-collectorId`, payload);
};

export const getAllCollectionsBySpeakerId = (payload: any) => {
    return post(`/api/v1/collection-by-speakerId`, payload);
};

export const getUniqueSpeakerId = (payload: any) => {
    return post(`/api/v1/speakerId-by-collector`, payload);
};


export const setCollectionStatusUpdate = (payload:any) => {
    return put(`/api/v1/update-collection-status`, payload);
};

export const setRejected =(payload:any) =>{
    return post(`/api/v1/annotation/checked-rejected`,payload)
}

// upload controller
export const deleteUploadedFolder = (speakerId:any) => {
    return del(`/uploads/delete/${speakerId}`);
};

export const getCollections = () => {
    return get(`/uploads/collections`);
};

export const saveMeta = (payload:any) => {
    return post(`/uploads/store`, payload);
};


export const {annotationLabels} = {
    annotationLabels: [
        'L1', 'L2', 'L3', 'L4'
    ]
}

export const getAllTopics = () => {
    return get(`/api/v1/getAllTopics`);
}

export const getTenTopics = () => {
    return get(`/api/v1/getTenTopics`);
}

export const submitConvData = (payload:any) => {
    return postFiletoPython(`/submit-conversation`, payload);
}

export const submitLectureData = (payload:any) => {
    return postFiletoPython(`/submit-lecture`, payload);
}